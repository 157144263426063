import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  HeatMapOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  DotChartOutlined,
  MenuFoldOutlined,
  AreaChartOutlined,
  CoffeeOutlined,
  MessageOutlined,
  LinkedinOutlined,
  LoginOutlined
} from '@ant-design/icons'
import { Dropdown, Menu, Form, Input, Button, message } from 'antd'
import './Homepage.css'
import HomepageImage from './images/homepage-image.png'

function Homepage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const onFinish = async (values) => {
    try {
      // Example POST request to a server-side script or email API
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}send-contact-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: values.message,
          from: values.email, // or your own email field
          name: values.name // or your own name field
        })
      })

      if (response.ok) {
        message.success('Message sent successfully!')
        form.resetFields()
      } else {
        message.error('Failed to send message. Please try again.')
      }
    } catch (error) {
      console.error('There was an error sending the message:', error)
      message.error('Failed to send message. Please try again.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Failed to send message. Please try again.')
  }
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <a href="#features" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <AreaChartOutlined style={{ marginRight: 8 }} />
          Features
        </a>
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={() => navigate('/login/')}>
        <span className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <CoffeeOutlined style={{ marginRight: 8 }} />
          Use Cases
        </span>
      </Menu.Item> */}
      <Menu.Item key="3">
        <a href="#contact-us" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <MessageOutlined style={{ marginRight: 8 }} />
          Contact Us
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a href="/login/" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <LoginOutlined style={{ marginRight: 8 }} />
          Log In
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: 'space-between'
        }}
        onClick={() => navigate('/')}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 20, marginRight: 8 }} />{' '}
          <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: 26, marginBottom: 0 }}>Insight Commons</h1>
        </div>

        <div className="navbar-links">
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Button
              className="login-navbar-link"
              style={{ fontWeight: 'bold', color: 'white', paddingRight: 0 }}
              type="link"
              overlayStyle={{ width: 350 }}
            >
              <MenuFoldOutlined />
              Menu
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="homepage-banner">
        <div className="homepage-banner-text-holder">
          <h1 className="homepage-banner-title">Democratizing Expert Knowledge</h1>
          <p className="homepage-banner-text">
            Insight Commons makes international development and foreign policy information easily accessible and
            understandable to everyone, whether you’re already a subject matter expert or you’re a student just
            beginning your journey.
          </p>
          <Button onClick={() => navigate('/register/')} type="secondary" style={{ fontWeight: 'bold', marginTop: 16 }}>
            Register for Free
          </Button>
          <div className="homepage-banner-icon-container">
            <div className="homepage-banner-icon-group">
              <DatabaseOutlined />
              <p>
                5 Million +<br />
                Reports and Datasets Available
              </p>
            </div>
            <div className="homepage-banner-icon-group">
              <GlobalOutlined />
              <p>
                2000 +<br />
                Organizations Represented
              </p>
            </div>
            <div className="homepage-banner-icon-group">
              <DotChartOutlined />
              <p>
                160,000 +<br />
                Analytical Insights Generated
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="information-container">
        <div className="information-container-desktop-a">
          <img alt="market-banner" src={HomepageImage} />
        </div>
        <div className="information-container-desktop-b">
          <h2>With Insight Commons you can:</h2>
          <div className="information-container-bullets">
            <h3>Easily Access Essential Resources</h3>
            <p>
              Effortlessly discover recent resources matching your interests in our immense repository with advanced
              full-text search technology. Insight Commons provides concise content summaries and shows you where to
              find the most relevant information, saving you time scrolling through documents and datasets.
            </p>
          </div>

          <div className="information-container-bullets">
            <h3>Gain Valuable Insights</h3>
            <p>
              Utilize domain-specific pipelines to automatically generate insights on various topics such as conflict,
              food insecurity, gender-based violence, migration, elections, and more. Use our research assistant to
              answer difficult questions and receive well-sourced answers, all traceable to their sources, empowering
              you to make informed decisions and save valuable time.
            </p>
          </div>
          <div className="information-container-bullets">
            <h3>Share Knowledge</h3>
            <p>
              Set up a shared commons to efficiently curate and distribute resources among your colleagues. Enhance
              communication using our threaded discussions feature and make the most of our literature review
              capabilities.
            </p>
          </div>
        </div>
      </div>
      <div className="ending-container">
        <div className="ending-container-sub">
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 32
            }}
          >
            <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 24, marginRight: 6 }} />{' '}
            <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: 26, marginBottom: 0 }}>Insight Commons</h1>
          </div>
          <h3>
            Join Insight Commons now to streamline your work, save time, and elevate the quality of your research.
          </h3>
          <Button onClick={() => navigate('/register/')} style={{ marginBottom: 32 }} type="primary">
            Register For Free
          </Button>
        </div>
      </div>
      <div id="contact-us" className="contact-container">
        <div className="contact-container-a">
          <div>
            <h4>Contact Us</h4>
            <p>Fill out the contact form or send us an email:</p>
            <a className="contact-container-a-link" href="mailto:support@insightcommons.com">
              support@insightcommons.com
            </a>
            <div className="contact-container-address">
              <p style={{ fontWeight: 'bold' }}>Insight Commons</p>
              <p>Washington DC</p>
            </div>

            <div>
              <a lassName="contact-container-a-linkedin" href="https://www.linkedin.com/company/insight-commons/">
                <LinkedinOutlined style={{ color: 'white', fontSize: 24 }} />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-container-b">
          <Form
            className="contact-form"
            form={form}
            name="contact_form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input placeholder="Your Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'The input is not valid E-mail!' }
              ]}
            >
              <Input placeholder="Your Email" />
            </Form.Item>

            <Form.Item name="message" rules={[{ required: true, message: 'Please input your message!' }]}>
              <Input.TextArea placeholder="Your Message" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send Message
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="homepage-footer">
        <p>&copy; 2024 Insight Commons. All Rights Reserved.</p>
      </div>
    </React.Fragment>
  )
}

export default Homepage
