import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Typography, TreeSelect, Input, Radio, Space, Button, message } from 'antd'
import countries from '../../utils/countries'
import themes from '../../utils/themes'
import Navbar from '../../components/Navbar/Navbar'
import './Commons.css'
const { Content } = Layout
const { Title } = Typography
const { TextArea } = Input
const { TreeNode } = TreeSelect

function NewCommons() {
  // Add Global
  countries['AA'] = 'Global'
  const navigate = useNavigate()
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [commonThemes, setCommonsThemes] = React.useState([])
  const [commonCountries, setCommonCountries] = React.useState([])
  const [commonPublic, setCommonPublic] = React.useState(true)
  const [messageApi, contextHolder] = message.useMessage()

  const createCommons = () => {
    if (!title || !description) {
      return messageApi.open({
        type: 'error',
        content: 'Please try again. Make sure to include a title and description.'
      })
    }
    // Get user id
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const userId = info.user?.id || info.user?.pk
    const token = info.access_token
    const body = {
      title,
      description,
      themes: commonThemes,
      countries: commonCountries,
      public: commonPublic,
      creator: userId
    }
    fetch(`${process.env.REACT_APP_BASE_URL}new-commons/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        messageApi.open({
          type: 'success',
          content: 'Your new commons was successfully created.'
        })
        function delay(time) {
          return new Promise((resolve) => setTimeout(resolve, time))
        }
        delay(3000).then(() => navigate('/'))
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  return (
    <React.Fragment>
      <Navbar />
      <Content className="new-commons-background">
        {contextHolder}
        <div className="new-commons-container">
          <Title style={{ fontWeight: 'bold' }} level={3}>
            Create a new Commons
          </Title>
          <p>
            Creating a new commons enables individuals or organizations to collaborate and share resources towards a
            common idea. A commons provides a platform for individuals to contribute, collaborate, and build upon shared
            resources.
          </p>
          <div className="new-commons-line" />
          <p className="input-title">Commons Name</p>
          <Input onChange={(e) => setTitle(e.target.value)} placeholder="Commons Name" />
          <p style={{ marginTop: 16, marginBottom: 0 }}>
            Great commons names are short and hint at the problem you are interested in researching. Need inspiration?
            How about <span style={{ color: '#13C2C2' }}>Technology and Humanitarian Aid</span>?
          </p>
          <p style={{ marginTop: 24 }} className="input-title">
            Themes of Interest
          </p>
          <TreeSelect
            showSearch
            treeCheckable
            style={{
              width: '100%',
              overflowX: 'auto'
            }}
            placeholder="Select Themes of Interest"
            allowClear
            onChange={setCommonsThemes}
          >
            {themes.map((theme) => {
              return <TreeNode key={theme} value={theme} title={theme}></TreeNode>
            })}
          </TreeSelect>
          <p style={{ marginTop: 16 }} className="input-title">
            Countries of Interest
          </p>
          <TreeSelect
            showSearch
            treeCheckable
            defaultValue={'Global'}
            onChange={setCommonCountries}
            style={{
              width: '100%',
              overflowX: 'auto',
              marginBottom: 16
            }}
            placeholder="Select Countries of Interest"
            allowClear
          >
            {Object.entries(countries)
              .sort((a, b) => (a > b ? 1 : -1))
              .map((country) => {
                return <TreeNode key={country[1]} value={country[1]} title={country[1]}></TreeNode>
              })}
          </TreeSelect>
          <div className="new-commons-line" />
          <p style={{ marginTop: 16 }}>Give a description of the problem you are interested in.</p>
          <TextArea onChange={(e) => setDescription(e.target.value)} rows={6} placeholder="Add a description" />
          <p style={{ marginTop: 8 }}>
            Be sure to enter a detailed description of your commons. We will use this as a starting point to reccommend
            literature that might be relevant.
          </p>
          <div className="new-commons-line" />
          <p style={{ marginTop: 16, marginBottom: 0 }}>
            <span style={{ color: '#13C2C2' }}>Public Commons:</span> Anyone on the internet can see this commons space.
            You choose who can contribute.
          </p>
          <p style={{ marginTop: 16, marginBottom: 16 }}>
            <span style={{ color: '#13C2C2' }}>Private Commons:</span> You choose who can see and commit to this commons
            space.
          </p>
          <Radio.Group
            onChange={(e) => setCommonPublic(e.target.value)}
            defaultValue={true}
            style={{ marginBottom: 16 }}
          >
            <Space direction="vertical">
              <Radio value={true}>
                Keep my commons <span style={{ color: '#13C2C2' }}>public</span>.
              </Radio>
              <Radio value={false}>
                Make my commons <span style={{ color: '#13C2C2' }}>private</span>.
              </Radio>
            </Space>
          </Radio.Group>
          <div className="new-commons-line" />
          <Button onClick={() => createCommons()} style={{ marginBottom: 54 }} type="primary">
            Create Commons
          </Button>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default NewCommons
