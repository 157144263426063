import * as React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'

function UploadDocs(params) {
  const { commonsInfo, messageApi } = params
  const [fileList, setFileList] = React.useState([])
  const [uploading, setUploading] = React.useState(false)
  const handleUpload = () => {
    const formData = new FormData()
    switch (fileList[0].type) {
      case 'application/pdf':
        formData.append('pdf', fileList[0])
        break
      case 'text/csv':
        formData.append('csv', fileList[0])
        break
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        formData.append('xlsx', fileList[0])
        break
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        formData.append('docx', fileList[0])
        break
      default:
        return messageApi.open({
          type: 'error',
          content:
            'There was an error uploading your document. Please make sure it is either a PDF, CSV, or Excel file.'
        })
    }
    formData.append('commons_id', commonsInfo.id)
    setUploading(true)
    fetch(`${process.env.REACT_APP_BASE_URL}private-file-upload/`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then((data) => {
        setFileList([])
        if ('error' in data) {
          if (data.error === 'File is too large')
            return messageApi.open({
              type: 'error',
              content: 'This File is too large. Files must be under 5MB.'
            })
        }
        if (data.success === 'document-exists') {
          messageApi.open({
            type: 'error',
            content: 'This document already exists.'
          })
        } else {
          messageApi.open({
            type: 'success',
            content: 'Your document has been uploaded.'
          })
        }
      })
      .catch((e) => {
        console.log('Error')
        console.log(e)
        messageApi.open({
          type: 'error',
          content: 'There was an error uploading your document. Please make sure it is a PDF.'
        })
      })
      .finally(() => {
        setUploading(false)
      })
  }
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    onchange: (info) => {
      console.log(info)
    },
    multiple: true,
    fileList
  }
  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content-settings">
        <div className="commons-view-main-content-settings-container">
          <h1 className="commons-view-main-content-settings-title">Add your Own Resources to this Commons Space</h1>
          <p>
            Upload private resources into this commons. These resources are only searchable and viewable by individuals
            who have access to this commons space.
          </p>
          <p>Supported File Types: PDF, DOCX, CSV, XSLX</p>
          <div className="commons-view-main-thread-divider" />
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginTop: 16
            }}
          >
            {uploading ? 'Uploading' : 'Start Upload'}
          </Button>
          <p style={{ marginTop: 48 }}>
            Want to upload your private documents in bulk? Send us an email at{' '}
            <a
              href="mailto:support@insightcommons.com?subject=Bulk Upload Request"
              style={{ color: '#13C2C2', cursor: 'pointer' }}
            >
              Insight Commons Support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default UploadDocs
