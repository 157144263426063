import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Layout, message, Input, Button } from 'antd'
import Navbar from '../../components/Navbar/Navbar'
const { Content } = Layout

function PasswordReset(params) {
  const { uidb64, token } = useParams()
  const [messageApi, contextHolder] = message.useMessage()
  const [newPassword1, setNewPassword1] = React.useState('')
  const [newPassword2, setNewPassword2] = React.useState('')

  const handleError = (message) => {
    messageApi.open({
      type: 'error',
      content: message
    })
  }

  const saveNewPassword = () => {
    if (newPassword1.length < 8) {
      handleError('Your password must be longer than 8 characters.')
    }
    if (newPassword1 !== newPassword2) {
      handleError('Your passwords do not match.')
    }
    fetch(`${process.env.REACT_APP_BASE_URL}auth/password/reset/confirm/`, {
      method: 'POST',
      body: JSON.stringify({ uid: uidb64, token: token, new_password1: newPassword1, new_password2: newPassword2 }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        messageApi.open({
          type: 'success',
          content: 'Your password has been reset!'
        })
        setTimeout(function (x) {
          localStorage.clear()
          return (window.location.href = '/login/')
        }, 3000)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  return (
    <React.Fragment>
      <Navbar />
      <Content className="explore-container">
        {contextHolder}
        <div className="commons-view-container">
          <div className="commons-view-container-content">
            <div className="commons-view-main-content-settings">
              <div className="commons-view-main-content-settings-container">
                <h1 className="commons-view-main-content-settings-title">Password Reset</h1>
                <div className="commons-view-main-thread-divider" />
                <p style={{ marginBottom: 8, marginTop: 16 }}>New Password</p>
                <Input type="password" onChange={(e) => setNewPassword1(e.target.value)} />
                <p style={{ marginBottom: 8, marginTop: 16 }}>Confirm Password</p>
                <Input type="password" onChange={(e) => setNewPassword2(e.target.value)} />
                <Button style={{ marginTop: 32 }} onClick={() => saveNewPassword()} type="primary">
                  Save Password
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default PasswordReset
