import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { HeatMapOutlined, MenuFoldOutlined, AreaChartOutlined, MessageOutlined, LoginOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Form, Input, Button, message } from 'antd'
import '../Homepage/Homepage.css'
import './use-cases.css'
// Images
import CreateCommons from './images/create-commons.png'
import Invite from './images/invite.png'
import Search from './images/search.png'
import ViewingResources from './images/viewing-resources.png'
import Question1 from './images/question1.png'
import Question2 from './images/question2.png'

function BusinessDevelopment() {
  const navigate = useNavigate()
  const download_sample = () => {
    window.open('https://docs.google.com/document/d/1WXyGEZSm7DOYGWOJQYT0Zha6XwmWX1z2vW0cW_Fqris/view', '_blank')
  }
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">
        <a href="#features" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <AreaChartOutlined style={{ marginRight: 8 }} />
          Features
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a href="#contact-us" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <MessageOutlined style={{ marginRight: 8 }} />
          Contact Us
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a href="/login/" className="login-navbar-link" style={{ fontWeight: 'bold' }}>
          <LoginOutlined style={{ marginRight: 8 }} />
          Log In
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: 'space-between'
        }}
        onClick={() => navigate('/')}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 20, marginRight: 8 }} />{' '}
          <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: 26, marginBottom: 0 }}>Insight Commons</h1>
        </div>

        <div className="navbar-links">
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Button
              className="login-navbar-link"
              style={{ fontWeight: 'bold', color: 'white', paddingRight: 0 }}
              type="link"
              overlayStyle={{ width: 350 }}
            >
              <MenuFoldOutlined />
              Menu
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="homepage-banner">
        <div className="homepage-banner-text-holder">
          <h1 className="homepage-banner-title">Using Insights Commons for Business Development</h1>
          <p className="homepage-banner-text">
            Insight Commons serves as a critical resource for organizations in the international development sector,
            offering access to over five million reports and datasets from more than 2,500 organizations to support
            informed responses to RFIs and RFPs on topics like conflict and food insecurity. The platform enhances
            proposal quality and efficiency through advanced analysis tools and an AI Research Assistant, enabling users
            to extract relevant insights, identify trends, and generate data-driven recommendations.
          </p>
          <Button onClick={() => download_sample()} type="secondary" style={{ fontWeight: 'bold', marginTop: 16 }}>
            Download Sample RFI
          </Button>
        </div>
      </div>
      <div className="use-cases-container">
        {/* Introduction */}
        <h2>Introduction</h2>
        <p>
          Insight Commons is an invaluable asset for organizations responding to Requests for Information (RFIs) and
          Requests for Proposals (RFPs) within the international development sector. With its vast repository of over
          five million reports and datasets from more than 2,500 organizations, it offers unparalleled access to current
          and historical data covering a wide array of critical topics, such as conflict, food insecurity, gender-based
          violence, agriculture, elections, etc. This wealth of information enables organizations to craft highly
          informed and data-driven responses to RFIs and RFPs, showcasing their understanding of the issues at hand and
          their capacity to address them effectively.
        </p>
        <p>
          Furthermore, the integration of analysis tools within Insight Commons allows users to quickly sift through
          this extensive data to extract relevant insights and trends. This capability not only enhances the quality of
          proposals but also significantly reduces the time and resources typically required for research. Making use of
          our AI Research Assistant, further augments this process, offering advanced analytical capabilities and
          generating comprehensive summaries or recommendations based on the vast data available. By leveraging Insight
          Commons, organizations can improve their competitiveness in the bidding process, demonstrating a deep
          understanding of the development landscape and proposing evidence-based solutions that are both innovative and
          grounded in real-world data.
        </p>
        <p>
          We will now guide you step by step on how you can use Insight Commons to respond to a hypothetical Request for
          Information (RFI) within the international development sector, demonstrating how to leverage the platform's
          vast resources and analytical tools to craft a compelling and data-driven response.
        </p>
        {/* Account Creation and Login */}
        <h2>Account Creation and Login</h2>
        <p>
          Visit Insight Commons and register for an account. If you already have an account, simply log in with your
          credentials.
        </p>
        <p>Once your account is set up, log in to access the full features of Insight Commons.</p>
        {/* Creating a Commons*/}
        <h2>Creating a Commons</h2>
        <p>
          <strong>Navigate to New Commons:</strong> After logging in, locate and click on the "New" button in the top
          left corner of the screen. This action initiates the process to create a new commons.
        </p>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={CreateCommons} alt="create-commons" />
        </div>
        <p>
          <strong>Commons Creation:</strong> A commons acts as a dedicated repository for resources and analytical tools
          tailored to a specific theme or project. It facilitates collaboration and sharing among users focused on a
          common goal.
        </p>
        <p>
          <strong>Choose a Name:</strong> Select a concise and descriptive name that reflects the focus of your research
          or project. For the purpose of responding to the RFI by USAID on reducing gender-based violence in Nigeria, we
          will name our commons "USAID/NIGERIA/GBV/2024-01 Response Initiative".
        </p>
        <p>
          <strong>Theme and Country Selection:</strong> For this RFI response, choose "Gender" as the theme of interest
          and Nigeria for the country of interest. This selection aligns the commons with the focus area of the RFI,
          ensuring that the resources and tools available will be relevant to gender-based violence prevention in
          Nigeria and response efforts.
        </p>
        <p>
          <strong>Provide a Description:</strong> In the description field, include a brief overview of the purpose of
          the commons, referencing the RFI's objectives. An example description could be, "This commons is dedicated to
          developing and sharing resources and strategies for reducing gender-based violence in Nigeria, in response to
          RFI Reference Number: USAID/NIGERIA/GBV/2024-01. Our goal is to address the root causes of GBV, support
          survivors, and promote gender equality and respect for women's rights."
        </p>
        <p>
          <string>Privacy Setting:</string> Given the sensitive nature of the work and potential internal documents, set
          your commons to "Private." This ensures that all resources are securely stored and only accessible to users
          whom you grant permission to.
        </p>
        {/* Invite Colleagues to Join Commons */}
        <h2>Invite Colleagues to Join Commons</h2>
        <p>
          <strong>Navigate Home:</strong> After completing the initial setup of your commons, return to the Insight
          Commons home screen.
        </p>
        <p>
          <strong>Locate Your Commons:</strong> On the home screen, find the section labeled "My Top Commons." Here, you
          will see the new commons you created listed on the left side of the screen.
        </p>
        <p>
          <strong>Open Commons Page:</strong> Click on the name of your commons to access its dedicated page.
        </p>
        <p>
          <strong>Explore Commons Tools:</strong> Upon arriving at your commons page, notice a list of tools positioned
          on the right, underneath the commons name. Before exploring these tools, we'll first add our colleagues to
          ensure they can access the commons.
        </p>
        <p>
          <strong>Settings Tab:</strong> Click on the "Settings" tab to manage your commons. This area allows for
          modifications such as changing the title, description, and adjusting user privileges.
        </p>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={Invite} alt="invite" />
        </div>
        <p>
          <strong>User Registration Requirement:</strong> Ensure your colleagues have registered for an account on
          Insight Commons before proceeding.
        </p>
        <p>
          <strong>Add New User:</strong> Within the settings, locate the option to "Add New User" and enter your
          colleague's email address to invite them to the commons.
        </p>
        <p>
          <strong>Assigning Privileges:</strong> Decide on the level of access and privileges each colleague should
          have:
          <ul>
            <li>Viewer: Can see resources and insights within the commons.</li>
            <li>Contributor: Has viewer privileges and can also request to add new resources.</li>
            <li>
              Maintainer and Admin: Have full access rights. Admins additionally have the ability to delete the commons
              if necessary.
            </li>
          </ul>
        </p>
        <p>
          <strong>Completing Invitations:</strong> After selecting the appropriate role, add your colleagues to the
          commons. This will enable them to view or contribute according to the privileges you’ve assigned.
        </p>
        <p>
          With your team now assembled within your commons, begin uploading internal resources, documents, and data
          relevant to the proposal.
        </p>
        {/* Uploading Resources to Your Commons */}
        <h2>Uploading Resources to Your Commons</h2>
        <p>
          <strong>Upload Resources Tab:</strong> Navigate to the top of your commons page and click on the "Upload
          Resources" tab. This area is designed for the addition of new resources to your commons from your own private
          collection.
        </p>
        <p>
          <strong>File Formats:</strong> Insight Commons supports uploading resources in various formats, including PDF,
          DOCX, CSV, and XLSX. These formats cover a wide range of data and document types, accommodating the diverse
          needs of your project.
        </p>
        <p>
          <strong>Privacy Settings:</strong> It's important to note that the resources you upload will be exclusively
          searchable and viewable by individuals who have access to this commons space. This ensures the privacy and
          security of your uploaded materials.
        </p>
        <p>
          <strong>Select and Upload File:</strong> Click on the "Select file" button to choose the resource you wish to
          upload from your device. Once selected, upload the file. The platform will process and add the resource to the
          "Resources" tab within your commons.
        </p>
        <p>
          <strong>AI-Generated Summary:</strong> After the upload, each resource will be accompanied by an AI-generated
          summary. This summary provides a quick overview of the document's content, making it easier for users to
          understand the resource at a glance.
        </p>
        <p>
          <strong>Bulk Uploads:</strong> If you have multiple documents you wish to upload to Insight Commons, there's a
          convenient option for bulk uploads. Simply send an email to Insight Commons Support expressing your need to
          upload documents in bulk.
        </p>
        <p>
          The support team at Insight Commons will collaborate with you to facilitate the bulk upload of your resources.
          They aim to complete this process within 24 hours, ensuring your commons is quickly populated with the
          necessary materials.
        </p>
        {/* Searching for Relevant Resources */}
        <h2>Searching for Relevant Resources</h2>
        <p>
          <strong>Accessing Resources:</strong> Navigate to the "Resources" tab on your screen, where all resources
          added to your commons will be displayed.
        </p>
        <p>
          <strong>Adding New Resources:</strong> To discover and add new resources, click on the "Add New Resources"
          button, which will direct you to the search interface.
        </p>
        <p>
          <strong>Efficient Search Strategies:</strong> Thanks to the comprehensive indexing of every page within our
          vast resource library, the most effective way to locate documents is through full-text search. Experiment with
          various search terms and filters to refine your search. We'll use terms like "GBV in Nigeria," "Reducing GBV
          in Nigeria," and apply the "country" filter to Nigeria for focused results.
        </p>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={Search} alt="search" />
        </div>
        <p>
          <strong>Understanding Search Results:</strong> The search results will provide valuable information about each
          resource, including the publication date, the organization that published it, the title, a relevancy score
          indicating the content's pertinence to your search, relevant page numbers, and a brief content snippet.
        </p>
        <p>
          <strong>Resource Selection:</strong> Upon finding a resource that aligns with our needs, you have the option
          to download it directly from its original source or to add it to your commons for centralized access.
        </p>
        <p>
          <strong>Adding Resources to Your Commons:</strong> Selecting "add to commons" triggers a prompt to choose the
          specific commons you want the resource added to and to provide a rationale for its inclusion. This step
          facilitates collaborative review within your team, allowing everyone to understand the context and relevance
          of each resource.
        </p>
        <p>
          <strong>Leveraging Analytical Tools:</strong> With a selection of relevant resources now incorporated into
          your commons, you're set to begin utilizing Insight Commons' analytical tools to derive insights and support
          your RFI response effectively.
        </p>
        {/* Viewing Resources */}
        <h2>Viewing Resources</h2>
        <p>
          Once you've added resources to your commons, follow these steps to navigate and utilize the added materials.
        </p>
        <p>
          <strong>Viewing Added Resources:</strong> Return to the main page of your commons to see a list of the
          resources you've added. Each listed resource will include an automatically generated summary for a quick
          overview.
        </p>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={ViewingResources} alt="viewing-resources" />
        </div>
        <p>
          <strong>Accessing Full Documents:</strong> Click on the title of any resource to access the option to download
          the full document directly from the original publishing organization.
        </p>
        <p>
          <strong>Discovering Recommended Documents:</strong> On the main commons page, scroll down and look to the
          right-hand side to find the "Recommended Documents" section. This area is dynamic and updates as new resources
          are added to your commons.
        </p>
        <p>
          The AI system analyzes the content of your added resources in relation to the entire resource database to
          suggest additional documents that might be relevant to your interests.
        </p>
        <p>To add a recommended resource to your commons, simply click the plus icon next to the resource.</p>
        {/* Run Insight Pipelines */}
        <h2>Run Insight Pipelines</h2>
        <p>
          <strong>Navigate to Insights:</strong> Head over to the "Insights" tab on your dashboard. This section allows
          for comprehensive analysis of the resources you've gathered in your commons.
        </p>
        <p>
          <strong>Analysis Options:</strong> Upon entering the insights tab, you'll find a table listing various
          analysis options. Some options are pre-selected by default, indicating they're applied automatically to your
          resources.
        </p>
        <p>
          <strong>Available Domain Insights:</strong> You have the option to apply additional insights relevant to your
          commons. The insights include:
          <ul>
            <li>Risk, Shocks, and Hazards</li>
            <li>Interventions</li>
            <li>Organizations</li>
            <li>Locations</li>
            <li>Food Insecurity</li>
            <li>Conflict</li>
            <li>Agriculture</li>
            <li>Migration</li>
            <li>Elections</li>
            <li>WASH</li>
          </ul>
        </p>
        <p>
          <strong>Choosing Relevant Insights for Your Commons:</strong> For a commons focused on specific topics like
          gender-based violence, food insecurity, and conflict, you can add these insights to tailor the analysis to
          your needs.
        </p>
        <p>
          <strong>Applying an Insight Pipeline:</strong> After selecting your desired insights, the analysis will
          commence, with results appearing within 30 seconds to 5 minutes. The analysis time varies as it involves
          examining each page of your resources for relevant information.
        </p>
        <p>
          <strong>Viewing Results:</strong> Results include radar visualizations offering trend analysis for each key
          indicator within the selected domains. Scrolling through the results, you'll see which documents are most
          relevant to each topic.
        </p>
        <p>
          <strong>Further Analysis:</strong> As you delve deeper, you'll find indicators for each topic being addressed,
          providing a granular view of the insights derived from your resources.
        </p>
        <p>
          <strong>Contact for Support:</strong> Should you have questions, suggestions for improvement, or specific
          analysis capabilities you wish to see developed, please don't hesitate to reach out to us at
          support@insightcommons.com.
        </p>
        {/* Utilizing the Research Assistant */}
        <h2>Utilizing the Research Assistant</h2>
        <p>
          <strong>Advanced AI Features:</strong> Our Research Assistant integrates the latest advancements in AI and
          large language models, enabling interactive queries and task assistance within the realm of your collected
          resources.
        </p>
        <p>
          <strong>Querying and Task Assistance:</strong> Begin by asking questions or requesting help with tasks. The
          Research Assistant will search for answers within the resources you've provided.
        </p>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={Question1} alt="question-1" />
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 56, marginBottom: 56 }}>
          <img style={{ width: '50%', width: '50%' }} src={Question2} alt="question-2" />
        </div>
        <p>
          <strong>Internet Access:</strong> Note that the Research Assistant cannot access the internet for information;
          it relies solely on the resources within your commons.
        </p>
        <p>
          <strong>Source Citation:</strong> Answers provided by the Research Assistant will include the source document
          and specific content, allowing for validation of its responses.
        </p>
        <p>
          <strong>Role of AI:</strong> Consider AI as a supportive tool rather than a replacement for human expertise.
          Always verify the AI-generated responses with expert review to ensure accuracy and relevance.
        </p>
        <p>
          <strong>Sample Queries and Tasks:</strong>
          <ul>
            <li>“Identify evidence-based strategies to reduce gender-based violence (GBV) in Nigeria.”</li>
            <li>“Suggest potential partnership opportunities for a new GBV program in Nigeria.”</li>
            <li>“Outline a Monitoring and Evaluation plan for a GBV program in Nigeria.”</li>
            <li>“Recommend key indicators for measuring progress in reducing GBV in Nigeria.”</li>
          </ul>
        </p>
        <p>
          <strong>Interpreting Responses:</strong> Expect responses from the Research Assistant within 2 to 40 seconds.
        </p>
        <p>
          <strong>Review and Validation:</strong> Upon receiving a response, examine both the answer and its sources.
          Before incorporating any information into your RFI submission, further review the original source for context
          and accuracy.
        </p>
        <p>
          <strong>Interactive Collaboration:</strong> Treat the Research Assistant as a collaborative colleague. Feel
          free to ask follow-up questions or discuss ideas to enhance your business development process.
        </p>
        {/* Final Thoughts */}
        <h2>Final Thoughts</h2>
        <p>
          <strong>AI in Development:</strong> The integration of AI tools in the development sector marks an exciting
          phase for leveraging vast amounts of published work globally. We are just beginning to explore the potential
          of AI to synthesize and interpret this information.
        </p>
        <p>
          <strong>Feedback and Inquiries:</strong> Your questions and thoughts are invaluable as we navigate this new
          territory. For any queries or feedback, please contact us at support@insightcommons.com.
        </p>
      </div>
      <div className="ending-container">
        <div className="ending-container-sub">
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 32
            }}
          >
            <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 24, marginRight: 6 }} />{' '}
            <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: 26, marginBottom: 0 }}>Insight Commons</h1>
          </div>
          <h3>
            Join Insight Commons now to streamline your work, save time, and elevate the quality of your research.
          </h3>
          <Button onClick={() => navigate('/register/')} style={{ marginBottom: 32 }} type="primary">
            Register For Free
          </Button>
        </div>
      </div>

      <div className="homepage-footer">
        <p>&copy; 2024 Insight Commons. All Rights Reserved.</p>
      </div>
    </React.Fragment>
  )
}

export default BusinessDevelopment
