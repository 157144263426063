const themes = [
  'Agriculture',
  'Camp Coordination and Camp Management',
  'Climate Change and Environment',
  'Conflict',
  'Contributions',
  'Coordination',
  'Disaster Management',
  'Education',
  'Food and Nutrition',
  'Gender',
  'Health',
  'HIV/Aids',
  'Humanitarian Financing',
  'Logistics and Telecommunications',
  'Mine Action',
  'Peacekeeping and Peacebuilding',
  'Protection and Human Rights',
  'Recovery and Reconstruction',
  'Safety and Security',
  'Shelter and Non-Food Items',
  'Water Sanitation Hygiene'
]

export default themes
