import * as React from 'react'
import { Layout, Button, Form, Input } from 'antd'
import { HeatMapOutlined } from '@ant-design/icons'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
const { Content } = Layout

function Register() {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState('')
  const [confirm, setConfirm] = React.useState(false)
  const onFinish = async (values) => {
    const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}auth/registration/`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const data = await fetchResponse.json()
    if (data['user'] || data['detail']) {
      setConfirm(true)
    } else {
      setErrorMessage('An error occured. Please try again.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <React.Fragment>
      <Navbar />
      <Content className="auth-background">
        {confirm === false ? (
          <div className="auth-container">
            <div className="auth-login-container">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 36, marginBottom: 24 }} />
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </div>
              <Form
                name="basic"
                labelCol={{
                  span: 24
                }}
                wrapperCol={{
                  span: 24
                }}
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      message: 'Please input your username!'
                    }
                  ]}
                >
                  <Input placeholder="user@insightcommons.com" />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      message: 'Please input your name!'
                    }
                  ]}
                >
                  <Input placeholder="John Doe" />
                </Form.Item>

                <Form.Item
                  label="Organization"
                  name="organization"
                  rules={[
                    {
                      message: 'Please input your name!'
                    }
                  ]}
                >
                  <Input placeholder="e.g. ACAPS, Gender Links, SIPRI" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password1"
                  rules={[
                    {
                      message: 'Please input your password!'
                    }
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="password2"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      message: 'Please confirm your password!'
                    }
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    span: 24
                  }}
                >
                  <Button style={{ width: '100%', marginTop: 32, marginBottom: 16 }} type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="auth-login-next-container">
              <p>
                Already have an account?{' '}
                <span style={{ color: '#13C2C2', cursor: 'pointer' }} onClick={() => navigate('/login/')}>
                  Login
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div className="auth-container">
            <div className="auth-login-container">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 36, marginBottom: 24 }} />
              </div>
              <p style={{ fontSize: 16, textAlign: 'center' }}>
                To finalize your registration,{' '}
                <span style={{ fontWeight: 'bold', fontSize: 18 }}>verify your email address</span> by clicking the link
                we've sent to your inbox.
              </p>
              <Button
                onClick={() => navigate('/login/')}
                style={{ width: '100%', marginTop: 32, marginBottom: 24 }}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </div>
          </div>
        )}
      </Content>
    </React.Fragment>
  )
}

export default Register
