import * as React from 'react'
import { Tag, Button, Tooltip } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'

function Requests(params) {
  const { commonsInfo, messageApi, getCommonsInfo } = params
  const [filteredLiterature, setFilteredLiterature] = React.useState([])
  const handleRequest = (doc, action) => {
    const body = action === 'accept' ? { pending_review: false } : { active: false, pending_review: false }
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    const id = doc.id
    const urlBase = doc.dataset === false ? 'commons-literature/' : 'commons-dataset/'
    fetch(`${process.env.REACT_APP_BASE_URL}${urlBase}${id}/`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        getCommonsInfo()
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const produceRejectReport = () => {
    const rejectList = commonsInfo.literature.filter((lit) => lit.active === false)
    const rows = [['Created At', 'Date Published', 'Paper Name', 'Organization', 'Document URL', 'Justification']]

    rejectList.forEach((element) => {
      rows.push([
        element.created_at,
        element.date_published,
        `"${element.paper_name}"`,
        `"${element.organization}"`,
        `"${element.document_url}"`,
        `"${element.justification || 'N/A'}"`
      ])
    })

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((row) => row.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
  }
  React.useEffect(() => {
    setFilteredLiterature(commonsInfo.literature.filter((lit) => lit.pending_review === true))
  }, [commonsInfo, setFilteredLiterature])
  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content">
        <Button onClick={() => produceRejectReport()} type="link" style={{ color: '#13c2c2', float: 'right' }}>
          Produce Rejected Documents Report
        </Button>
        <div className="commons-view-document-table" style={{ marginTop: 12 }}>
          <div
            className="commons-view-document-table-title"
            style={{ backgroundColor: '#F5F5F5', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>{commonsInfo?.title}</p>
              {commonsInfo?.public ? <Tag color="cyan">Public</Tag> : <Tag color="purple">Private</Tag>}
            </div>
            <p className="commons-view-requests-document-count">
              {filteredLiterature.length} Pending Document Requests
            </p>
          </div>
          {/* Results */}
          {commonsInfo?.literature
            ? filteredLiterature.map((doc) => {
                return (
                  <div key={doc.paper_name} className="commons-view-request-table-result">
                    <div className="commons-view-request-table-result-column1">
                      <FileTextOutlined style={{ color: '#13C2C2', marginRight: 12 }} />
                      <div>
                        <a
                          style={{ color: 'rgba(0, 0, 0, 0.88)', fontSize: 14 }}
                          href={doc.document_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p>{doc.paper_name}</p>
                        </a>
                        <p style={{ color: '#8C8C8C', fontSize: 12 }}>
                          {doc.organization} | {doc.date_published.substring(0, 10)}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="commons-view-request-table-result-two">
                      <Tooltip placement="top" title={doc.justification}>
                        <Button onClick={() => handleRequest(doc, 'reject')} style={{ color: 'red' }} type="link">
                          Reject
                        </Button>
                      </Tooltip>
                      <Tooltip placement="top" title={doc.justification}>
                        <Button onClick={() => handleRequest(doc, 'accept')} type="link">
                          Accept
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <div className="commons-view-second-content">
        <p className="commons-view-second-content-title">Summary of Commons</p>
        <p>{commonsInfo?.description}</p>
        <p className="commons-view-second-content-title">Keywords</p>
        <div className="commons-view-second-content-keyword-container">
          {commonsInfo?.themes
            ? commonsInfo?.themes.map((keyword) => {
                return <Tag>{keyword}</Tag>
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default Requests
