import * as React from 'react'
import {  Modal, Input } from 'antd'

function RequestSourceModal(props) {
    const {isModalOpen, messageApi, setShowingRequestResourceModal} = props
    const [organizationName, setOrganizationName] = React.useState('')
    const [organizationUrl, setOrganizationUrl] = React.useState('')

    const handleCancel = () => {
        setShowingRequestResourceModal(false)
    }
    const handleOk = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}request-resource/`, {
            method: 'POST',
            body: JSON.stringify({ new_source_organization_name: organizationName, new_source_organization_url: organizationUrl }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          })
            .then((response) => response.json())
            .then((data) => {
                // Close modal
                setShowingRequestResourceModal(false)
                return messageApi.open({
                    type: 'success',
                    content: 'Resource has been requested.'
                  })
            .catch(() => {
                setShowingRequestResourceModal(false)
                return messageApi.open({
                    type: 'error',
                    content: 'Failed to request resource.'
                  })
            })
        }
            )}
    return (
        <Modal
            style={{ zIndex: 10000000 }}
            title="Request a resource to be added"
            visible={isModalOpen}
            onOk={handleOk}
            okText={'Request Resource'}
            onCancel={() => handleCancel()}
          >
            <p>
              <span style={{fontWeight: 'bold'}}>Want us to add a new resource pipeline?</span> Give us some information and we will work on building out a pipeline to pull in all of the resources from a specific organization.
            </p>
            <p className="mobile-seach-filters-tree-titles">Organization Name</p>
            <Input onChange={e => setOrganizationName(e.target.value)} placeholder='Organization Name' />
            <p className="mobile-seach-filters-tree-titles" style={{marginTop: 16}}>Organization Url</p>
            <Input onChange={e => setOrganizationUrl(e.target.value)} placeholder='Organization Url' />
          </Modal>
    )
}

export default RequestSourceModal