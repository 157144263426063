import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Input, Button, Mentions } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import ReactMarkdown from 'react-markdown'
const { TextArea } = Input

function Threads(params) {
  const containerRef = React.useRef(null)
  const { messageApi, commonsAuth, commonsInfo } = params
  const { handle } = useParams()
  const [threads, setThreads] = React.useState([])
  const [newMessage, setNewMessage] = React.useState('')
  const [contributers, setContributers] = React.useState('')
  const [addingSubcomment, setAddingSubComment] = React.useState(false)
  const [subcommentParent, setSubCommentParent] = React.useState('')

  const getCommonsUsers = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth-common/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: commonsAuth.commons_id_id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setContributers(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const getThreads = () => {
    // Access the container element using ref
    const container = containerRef.current

    // Scroll to the bottom of the container
    if (container) {
      container.scrollTop = container.scrollHeight
    }
    // Get commons info
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}commons/${handle}/comments/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setThreads(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  React.useEffect(() => {
    getThreads()
    getCommonsUsers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle])

  const addNewThreadComment = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    const commentPayload = convertTextToPayload(newMessage)

    fetch(`${process.env.REACT_APP_BASE_URL}comments/create/`, {
      method: 'POST',
      body: JSON.stringify(commentPayload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setNewMessage('')
        getThreads()
      })
      .catch((error) => {
        console.log(error)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  function convertTextToPayload(text) {
    // Create the final payload object
    const payload = {
      text,
      references: [],
      commons_id: handle // Replace 'handle' with the actual value you want to include
    }

    return payload
  }

  const deleteComment = (comment) => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}comments/mark-deleted/${comment.id}/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        getThreads()
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const getMentionsUrl = (mentionText) => {
    const lit_info = commonsInfo.literature.filter((lit) => lit.paper_name === mentionText)
    return lit_info[0].document_url
  }

  function removeLastAtSign(str) {
    if (str.charAt(str.length - 1) === '@') {
      return str.slice(0, -1) // Remove the last character
    }
    return str // No "@" sign at the end, return the original string
  }

  const addAsSubComment = (comment) => {
    setAddingSubComment(true)
    setSubCommentParent(comment)
  }

  const returnCommentUI = (comment, parts) => {
    return (
      <div key={comment.created_at}>
        {comment.author === commonsAuth.user_id_id ? (
          <DeleteOutlined
            onClick={() => deleteComment(comment)}
            style={{ cursor: 'pointer', color: 'red', position: 'absolute', right: 8, top: 8 }}
          />
        ) : null}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontWeight: 'bold' }} className="commons-view-main-thread-message-info">
            {comment.author_name}
          </p>
          <p className="commons-view-main-thread-message-info" style={{ marginLeft: 12 }}>
            {comment.created_at.substring(0, 10)}
          </p>
        </div>
        <p className="commons-view-main-thread-message-text">
          {parts.map((part, index) => {
            if (index % 2 === 0) {
              return <ReactMarkdown>{removeLastAtSign(part)}</ReactMarkdown>
            } else {
              const mentionText = part.trim().replace(/^@/, '') // Remove "@" sign
              // Convert {{mention}} into an <a> tag without HTML escaping
              return (
                <React.Fragment key={index}>
                  <a href={getMentionsUrl(mentionText)}>{mentionText}</a>
                </React.Fragment>
              )
            }
          })}
        </p>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button
            style={{
              paddingRight: 0
            }}
            onClick={() => addAsSubComment(comment)}
            type="link"
          >
            Add Sub Comment
          </Button>
        </div>
      </div>
    )
  }

  const saveSubComment = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    const commentPayload = convertTextToPayload(newMessage)
    commentPayload['comment_id'] = subcommentParent.id
    fetch(`${process.env.REACT_APP_BASE_URL}comments/create-subcomment/`, {
      method: 'POST',
      body: JSON.stringify(commentPayload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setNewMessage('')
        getThreads()
        setAddingSubComment(false)
        setSubCommentParent('')
      })
      .catch((error) => {
        console.log(error.text)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content" style={{ paddingLeft: 32 }}>
        <p className="commons-view-main-thread-count">{threads.length} Comments</p>
        <div className="commons-view-main-thread-container" ref={containerRef}>
          {threads.map
            ? threads.map((comment) => {
                const text = comment.text
                const mentionRegex = /{{@?([^{}]+)}}/g
                const parts = text.split(mentionRegex)
                return (
                  <div key={comment.created_at} className="commons-view-main-thread-message-container">
                    {returnCommentUI(comment, parts)}
                    {comment.subcomments.map((subcomment) => {
                      const subCommenttext = subcomment.text
                      const subCommentparts = subCommenttext.split(mentionRegex)
                      return (
                        <div style={{ borderTop: '1px solid lightgray', paddingTop: 16, paddingBottom: 16 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p
                              style={{ fontWeight: 'bold', marginLeft: 48 }}
                              className="commons-view-main-thread-message-info"
                            >
                              {subcomment.author_name}
                            </p>
                            <p className="commons-view-main-thread-message-info" style={{ marginLeft: 12 }}>
                              {subcomment.created_at.substring(0, 10)}
                            </p>
                          </div>
                          <p style={{ marginLeft: 48 }} className="commons-view-main-thread-message-text">
                            {subCommentparts.map((subpart, index) => {
                              if (index % 2 === 0) {
                                return <ReactMarkdown>{removeLastAtSign(subpart)}</ReactMarkdown>
                              } else {
                                const mentionText = subpart.trim().replace(/^@/, '') // Remove "@" sign
                                // Convert {{mention}} into an <a> tag without HTML escaping
                                return (
                                  <React.Fragment key={index}>
                                    <a href={getMentionsUrl(mentionText)}>{mentionText}</a>
                                  </React.Fragment>
                                )
                              }
                            })}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                )
              })
            : null}
        </div>
        {commonsAuth?.admin || commonsAuth?.maintainer || commonsAuth?.contributor ? (
          <>
            <Mentions
              value={newMessage}
              onChange={(value) => setNewMessage(value)}
              rows={6}
              placeholder={
                addingSubcomment === true
                  ? `Add a new sub comment for Comment: ${subcommentParent.id}`
                  : 'Add a new comment'
              }
            >
              {commonsInfo.literature.map((lit) => {
                return <Mentions.Option value={`{{${lit.paper_name}}}`}>{lit.paper_name}</Mentions.Option>
              })}
            </Mentions>
            <p style={{ color: 'lightgray' }}>Enter "@" to embed a resource into the chat.</p>
            <Button
              onClick={addingSubcomment === true ? () => saveSubComment() : () => addNewThreadComment()}
              type="primary"
              style={{ float: 'right', marginTop: 16 }}
            >
              {addingSubcomment === true ? 'Add Sub Comment' : 'Add Comment'}
            </Button>
          </>
        ) : null}
      </div>
      <div className="commons-view-second-content">
        <div className="commons-view-thread-contributer-container">
          <div className="commons-view-thread-contributer-title-container">
            <p style={{ fontWeight: 'bold' }}>Thread Contributors</p>
          </div>
          {contributers
            ? contributers.map((user) => {
                return (
                  <div key={user.id} className="commons-view-thread-contributer-name-container">
                    <p className="commons-view-thread-contributer-name-container-name">{user.user_name}</p>
                    <p className="commons-view-thread-contributer-name-container-org">{user.user_organization}</p>
                  </div>
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default Threads
