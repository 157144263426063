import * as React from 'react'
import { Typography } from 'antd'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'
const { Title } = Typography

function Chart(params) {
  const { title, data, createRadarData, description } = params
  return (
    <div>
      <Title level={5}>{title}</Title>
      <p>{description}</p>
      <div style={{ height: 450 }}>
        {createRadarData ? (
          <RadarChart cx={550} cy={250} outerRadius={150} width={1065} height={451} data={createRadarData(data)}>
            <PolarGrid />
            <PolarAngleAxis dataKey="title" />
            <PolarRadiusAxis />
            <Radar name={title} dataKey="A" stroke="#000000" fill="#13c2c2" fillOpacity={0.6} />
          </RadarChart>
        ) : null}
      </div>
    </div>
  )
}

export default Chart
