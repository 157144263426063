import * as React from 'react'
import Chart from './Chart'

function MigrationPipeline(params) {
  const { data, createRadarData } = params
  const migration_types = data[0].data.filter((insight) => insight.insight_type === 'migration_types')
  const migration_reasons = data[0].data.filter((insight) => insight.insight_type === 'migration_reasons')
  const migration_challenges = data[0].data.filter((insight) => insight.insight_type === 'migration_challenges')
  const migration_legal_policy_dimensions = data[0].data.filter(
    (insight) => insight.insight_type === 'migration_legal_policy_dimensions'
  )
  const migration_stakeholders = data[0].data.filter((insight) => insight.insight_type === 'migration_stakeholders')
  const migration_impacts = data[0].data.filter((insight) => insight.insight_type === 'migration_impacts')

  const loadHeatmap = () => {
    const agg_types = [
      'migration_types',
      'migration_reasons',
      'migration_challenges',
      'migration_legal_policy_dimensions',
      'migration_stakeholders',
      'migration_impacts'
    ]

    agg_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Types of Migration"
        data={migration_types}
        createRadarData={createRadarData}
        description="This component categorizes and characterizes various forms of migration, including internal migration, international migration, forced migration, and voluntary migration, to provide insights into the nature of human mobility."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_types"></div>
      </div>
      <Chart
        title="Reason for Migration"
        data={migration_reasons}
        createRadarData={createRadarData}
        description="The pipeline seeks to uncover the motivations and drivers behind migration, including economic, political, environmental, social, and personal factors that influence individuals or groups to move."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_reasons"></div>
      </div>
      <Chart
        title="Migration Challenges"
        data={migration_challenges}
        createRadarData={createRadarData}
        description="Identifying migration challenges involves capturing the obstacles, difficulties, and risks that migrants may encounter during their journeys or after resettlement, such as legal barriers, discrimination, economic hardships, and access to services."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_challenges"></div>
      </div>
      <Chart
        title="Legal Policy Dimensions"
        data={migration_legal_policy_dimensions}
        createRadarData={createRadarData}
        description="This aspect focuses on the legal and policy dimensions related to migration, including immigration laws, border policies, visa regulations, and international agreements like refugee conventions."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_legal_policy_dimensions"></div>
      </div>
      <Chart
        title="Migration Stakeholders"
        data={migration_stakeholders}
        createRadarData={createRadarData}
        description="The pipeline identifies and profiles the various actors involved in migration, including government agencies, non-governmental organizations (NGOs), international bodies, advocacy groups, and communities impacted by migration."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_stakeholders"></div>
      </div>
      <Chart
        title="Impacts of Migration"
        data={migration_impacts}
        createRadarData={createRadarData}
        description="This component assesses the multifaceted impacts of migration on both sending and receiving regions, including economic contributions, cultural changes, demographic shifts, and social integration effects."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="migration_impacts"></div>
      </div>
    </>
  )
}

export default MigrationPipeline
