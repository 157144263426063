import * as React from 'react'
import { Input, Button, Switch, TreeSelect } from 'antd'
import contentTypes from '../../../utils/content-types'
import countries from '../../../utils/countries'
import themes from '../../../utils/themes'
const { Search } = Input
const { TreeNode } = TreeSelect

function SearchSidebar(props) {
  const { setSearchResults, setRunningSearch, messageApi, datasets, setDatasets } = props
  const [organizationsOptions, setOrganizationsOptions] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [searchOrganization, setSearchOrganization] = React.useState([])
  const [searchTheme, setSearchTheme] = React.useState([])
  const [searchContentType, setSearchContentType] = React.useState([])
  const [mostRecent, setMostRecent] = React.useState(false)
  const [searchCountry, setSearchCountry] = React.useState([])
  const getOrganizations = () => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}get-organizations/`)
        .then((response) => response.json())
        .then((data) => {
          const orgList = data.organizations.map((org) => {
            return org.organization
          })

          setOrganizationsOptions(orgList)
        })
    } catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    getOrganizations(token)
  }, [])

  const searchDatasets = () => {
    setRunningSearch(true)
    fetch(`${process.env.REACT_APP_BASE_URL}make-dataset-query/`, {
      method: 'POST',
      body: JSON.stringify({
        search,
        content_type: searchContentType,
        theme: searchTheme,
        most_recent: mostRecent,
        country: searchCountry,
        organization: searchOrganization,
        postgres_ids: []
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data)
        setRunningSearch(false)
      })
      .catch((error) => {
        setRunningSearch(false)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const getResults = () => {
    // Use a new api endpoint if the user is looking for Datasets
    if (datasets === true) {
      return searchDatasets()
    }
    setRunningSearch(true)
    fetch(`${process.env.REACT_APP_BASE_URL}query/`, {
      method: 'POST',
      body: JSON.stringify({
        search,
        content_type: searchContentType,
        theme: searchTheme,
        country: searchCountry,
        organization: searchOrganization,
        most_recent: mostRecent || false,
        postgres_ids: [],
        datasets: datasets
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data)
        setRunningSearch(false)
      })
      .catch((error) => {
        setRunningSearch(false)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  return (
    <>
      <div className="top-commons-heading">
        <h2>Search</h2>
      </div>
      <Search
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => getResults()}
        style={{ marginTop: 16, marginBottom: 16 }}
        placeholder="Search by full text"
        size="small"
        enterButton
      />
      <h3 className="search-filters-sidebar-title">Search Filters</h3>
      <p className="mobile-seach-filters-tree-titles">Theme</p>
      <TreeSelect
        showSearch
        value={searchTheme || []}
        treeCheckable
        style={{
          width: '100%',
          height: 32,
          overflowX: 'auto'
        }}
        onChange={(e) => setSearchTheme(e)}
        placeholder="Select a Theme of Interest"
        allowClear
      >
        {themes.map((theme) => {
          return <TreeNode key={theme} value={theme} title={theme}></TreeNode>
        })}
      </TreeSelect>
      <p style={{ marginTop: 8 }} className="mobile-seach-filters-tree-titles">
        Country
      </p>
      <TreeSelect
        showSearch
        style={{
          width: '100%'
        }}
        treeCheckable
        onChange={(e) => setSearchCountry(e)}
        placeholder="Select a Country of Interest"
        allowClear
        value={searchCountry || []}
      >
        {Object.entries(countries)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map((country) => {
            return <TreeNode key={country[1]} value={country[1]} title={country[1]}></TreeNode>
          })}
      </TreeSelect>

      <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
        Organizations
      </p>
      <TreeSelect
        showSearch
        treeCheckable
        onChange={(e) => setSearchOrganization(e)}
        style={{
          width: '100%'
        }}
        placeholder="Select an Organization"
        allowClear
        multiple
        value={searchOrganization || []}
      >
        {organizationsOptions
          .sort((a, b) => (a > b ? 1 : -1))
          .map((organization) => {
            return <TreeNode key={organization} value={organization} title={organization}></TreeNode>
          })}
      </TreeSelect>
      <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
        Content Type
      </p>
      <TreeSelect
        showSearch
        value={searchContentType || []}
        treeCheckable
        style={{
          width: '100%',
          height: 32,
          overflowX: 'auto'
        }}
        onChange={(e) => setSearchContentType(e)}
        placeholder="Select a Content Type"
        allowClear
      >
        {contentTypes.map((type) => {
          return <TreeNode key={type} value={type} title={type}></TreeNode>
        })}
      </TreeSelect>
      <p style={{ marginTop: 8 }} className="mobile-seach-filters-tree-titles">
        Filter by Most Recent?
      </p>
      <Switch onChange={(e) => setMostRecent(e)} value={mostRecent} checkedChildren="Yes" unCheckedChildren="No" />
      <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
        Filter for Datasets?
      </p>
      <Switch onChange={(e) => setDatasets(e)} value={datasets} checkedChildren="Yes" unCheckedChildren="No" />
      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <Button onClick={() => getResults()} style={{ width: 120 }} size="small" type="primary">
          Update Search
        </Button>
      </div>
    </>
  )
}

export default SearchSidebar
