import * as React from 'react'
import { Typography } from 'antd'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'
const { Title } = Typography

function Chart(params) {
  const { title, data, createRadarData, description } = params
  return (
    <div>
      <Title level={5}>{title}</Title>
      <p>{description}</p>
      <div style={{ height: 450 }}>
        {createRadarData ? (
          <RadarChart cx={550} cy={250} outerRadius={150} width={1065} height={451} data={createRadarData(data)}>
            <PolarGrid />
            <PolarAngleAxis dataKey="title" />
            <PolarRadiusAxis />
            <Radar name={title} dataKey="A" stroke="#000000" fill="#13c2c2" fillOpacity={0.6} />
          </RadarChart>
        ) : null}
      </div>
    </div>
  )
}

function ConflictPipeline(params) {
  const { data, createRadarData } = params
  const conflict_drivers = data[0].data.filter((insight) => insight.insight_type === 'conflict_drivers')
  const conflict_events = data[0].data.filter((insight) => insight.insight_type === 'conflict_events')
  const conflict_gbv = data[0].data.filter((insight) => insight.insight_type === 'conflict_gbv')
  const conflict_human_rights = data[0].data.filter((insight) => insight.insight_type === 'conflict_human_rights')
  const conflict_impacts = data[0].data.filter((insight) => insight.insight_type === 'conflict_impacts')
  const conflict_peace_resolutions = data[0].data.filter(
    (insight) => insight.insight_type === 'conflict_peace_resolutions'
  )

  const loadHeatmap = () => {
    const conflict_types = [
      'conflict_drivers',
      'conflict_events',
      'conflict_gbv',
      'conflict_human_rights',
      'conflict_impacts',
      'conflict_peace_resolutions'
    ]

    conflict_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Drivers of Conflict"
        data={conflict_drivers}
        createRadarData={createRadarData}
        description="This aspect focuses on identifying the root causes and contributing factors that lead to conflicts. It involves the analysis of economic, political, social, and environmental factors that trigger or exacerbate conflicts."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_drivers"></div>
      </div>

      <Chart
        title="Conflict Events"
        data={conflict_events}
        createRadarData={createRadarData}
        description="Conflict events refer to specific incidents or occurrences related to conflicts. This aspect involves tracking and analyzing events such as protests, violence, negotiations, and peace talks to gain insights into the dynamics of conflicts."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_events"></div>
      </div>

      <Chart
        title="Gender Based Violence"
        data={conflict_gbv}
        createRadarData={createRadarData}
        description="Gender-based violence is a critical issue within conflicts. This aspect explores incidents of violence that disproportionately affect individuals based on their gender, including violence against women and other vulnerable groups."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_gbv"></div>
      </div>

      <Chart
        title="Human Rights Violations"
        data={conflict_human_rights}
        createRadarData={createRadarData}
        description="Human rights violations encompass actions that infringe upon the fundamental rights and dignity of individuals during conflicts. This aspect involves monitoring and documenting such violations to assess their impact on human rights and international law."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_human_rights"></div>
      </div>

      <Chart
        title="Community Impacts of Conflict"
        data={conflict_impacts}
        createRadarData={createRadarData}
        description="Conflict rarely affects only the parties directly involved; it has broader societal impacts. This aspect examines how conflicts affect communities, including displacement, access to resources, economic stability, and social cohesion."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_impacts"></div>
      </div>

      <Chart
        title="Peace Resolutions"
        data={conflict_peace_resolutions}
        createRadarData={createRadarData}
        description="This aspect focuses on efforts to resolve conflicts and promote peace. It includes the study of peace agreements, negotiations, reconciliation processes, and strategies aimed at mitigating or ending conflicts."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="conflict_peace_resolutions"></div>
      </div>
    </>
  )
}

export default ConflictPipeline
