import * as React from 'react'
import { Button, Modal, Select, Input, Spin, Typography } from 'antd'
import { refreshToken } from '../../../utils/fetch'
import RequestSourceModal from './RequestSourceModal'
import DatasetResult from './DatasetResult'
const { TextArea } = Input
const { Title } = Typography

function SearchContent(props) {
  const { searchResults, messageApi, runningSearch, datasets } = props
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalInfo, setModalInfo] = React.useState({ date: '', organization: '', paperName: '' })
  const [commons, setCommons] = React.useState([])
  const [commonsDestination, setCommonsDestination] = React.useState()
  const [documentJustification, setDocumentJustification] = React.useState('')
  const [selectedCommonsAuth, setSelectedCommonsAuth] = React.useState()
  const [processingDocument, setProcesingDocument] = React.useState(false)
  const [showingRequestResourceModal, setShowingRequestResourceModal] = React.useState(false)

  const addDocToCommons = (paperName, date, organization, result) => {
    // Set modal info
    setModalInfo({ paperName, date, organization, result })
    // Get commons access by user
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const user = info.user?.id || info.user?.pk
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}commons-user-id/`, {
      method: 'POST',
      body: JSON.stringify({ user, ignore_guidance: true }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          return refreshToken(addDocToCommons)
        }
        return response.json()
      })
      .then((data) => {
        if (Array.isArray(data)) {
          // Add label and value
          data.forEach((element) => {
            element.label = element.title
            element.value = element.id
          })
          setCommons(data)
          setIsModalOpen(true)
        }
      })
      .catch((error) => {
        console.log(error)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  const handleCommonsDestination = (value) => {
    setCommonsDestination(value)
    // Look up auth for specific commons
    const commonsAuth = commons.filter((commons) => commons.id === value)
    setSelectedCommonsAuth(commonsAuth[0].auth)
  }
  const saveDocToCommons = () => {
    const body = {
      commons_id: commonsDestination,
      paper_name: modalInfo['paperName'],
      justification: documentJustification,
      needs_review: selectedCommonsAuth.admin === true || selectedCommonsAuth.maintainer === true ? 'false' : 'true',
      result: modalInfo['result'],
      dataset: datasets ? true : false
    }
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    setProcesingDocument(true)
    fetch(`${process.env.REACT_APP_BASE_URL}add-literature-to-commons/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.status === 401) {
          return refreshToken(saveDocToCommons)
        }
        return response.json()
      })
      .then((data) => {
        setProcesingDocument(false)
        setIsModalOpen(false)
        setDocumentJustification('')
        messageApi.open({
          type: 'success',
          content: 'Added Document to Commons'
        })
      })
      .catch((error) => {
        console.log(error)
        setProcesingDocument(false)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  return (
    <>
      <Modal
        okText={'Add to Commons'}
        width={350}
        className="search-results-modal"
        title="Add to Commons"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => saveDocToCommons()}
      >
        {processingDocument ? (
          <div style={{ width: '100%', textAlign: 'center', height: 340, paddingTop: 100 }}>
            <Spin size="large" />
            <Title style={{ color: 'gray', marginTop: 16 }} level={5}>
              SAVING DOCUMENT
            </Title>
          </div>
        ) : (
          <>
            <p style={{ marginBottom: 8 }} className="search-result-container-date-organization">
              {modalInfo.date} | <span style={{ color: '#13C2C2' }}>{modalInfo.organization}</span>
            </p>
            <p className="search-result-container-title" style={{ fontSize: 16, marginTop: 0 }}>
              {modalInfo.paperName}
            </p>
            <p style={{ marginBottom: 8, marginTop: 32 }}>Select a Commons Space</p>
            <Select
              onSelect={(value) => handleCommonsDestination(value)}
              style={{
                width: '100%'
              }}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={commons}
            />
            <p style={{ marginBottom: 8, marginTop: 16 }}>Give a reason for adding this document.</p>
            <TextArea
              value={documentJustification}
              required={selectedCommonsAuth?.maintainer === true ? false : true}
              onChange={(e) => setDocumentJustification(e.target.value)}
              placeholder="What is the reason for including this document?"
              rows={4}
            />
          </>
        )}
      </Modal>
      <RequestSourceModal
        setShowingRequestResourceModal={setShowingRequestResourceModal}
        isModalOpen={showingRequestResourceModal}
        messageApi={messageApi}
      />
      <div className="search-result-container-holder">
        <Button
          onClick={() => setShowingRequestResourceModal(true)}
          type="link"
          style={{ position: 'absolute', right: 0, top: 80, fontSize: 16 }}
        >
          Request New Resource
        </Button>
        {runningSearch ? (
          <div style={{ height: '100%', width: '100%', display: 'flex', marginTop: 200, justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        ) : searchResults.results?.length > 0 ? (
          searchResults.results?.map((result) => {
            if (datasets === false) {
              result = result[1]
              const datePublished = result.date_published.substring(0, 10)
              const score = result.score.toString().substring(0, 5)
              return (
                <div key={result.paper_name} className="search-result-container">
                  <a href={result.document_url} target="_blank" rel="noreferrer">
                    <Button className="search-result-container-link" size="small" type="link">
                      Download from Source
                    </Button>
                  </a>
                  <p className="search-result-container-date-organization">
                    {datePublished} | <span style={{ color: '#13C2C2' }}>{result.organization}</span>
                  </p>
                  <p className="search-result-container-title">{result.paper_name}</p>
                  <p className="search-result-container-details">Relevance Score: {score}</p>
                  <p className="search-result-container-details">
                    Relevant Pages:{' '}
                    {result.pages.map((page, index) => {
                      if (index !== result.pages.length - 1) {
                        page = page + ','
                      }
                      return <span key={page}>{page} </span>
                    })}
                  </p>
                  <p className="search-result-container-text-snippet">
                    {'...'}
                    {result.highlight.map((text) => {
                      return `${text}...`
                    })}
                  </p>
                  <div style={{ textAlign: 'right', marginTop: 32 }}>
                    <Button
                      onClick={() => addDocToCommons(result.paper_name, datePublished, result.organization, result)}
                      style={{ marginBottom: 16 }}
                      size="small"
                      type="primary"
                    >
                      Add to Commons
                    </Button>
                  </div>
                </div>
              )
            } else {
              return (
                <DatasetResult
                  addDocToCommons={addDocToCommons}
                  key={result.id}
                  messageApi={messageApi}
                  result={result}
                />
              )
            }
          })
        ) : (
          <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', color: 'gray' }}>
              <Title style={{ marginTop: 200, color: 'gray' }} level={3}>
                Over 5 Million Documents Available
              </Title>
              <p>Search by text, author, organization, keyword, etc.</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SearchContent
