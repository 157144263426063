const languageList = [
    {value: "", label: "All"},
    { value: 'afr', label: 'Afrikaans' },
    { value: 'sqi', label: 'Albanian' },
    { value: 'ara', label: 'Arabic' },
    { value: 'hye', label: 'Armenian' },
    { value: 'axe', label: 'Azerbaijani' },
    { value: 'ben', label: 'Bengali' },
    { value: 'bos', label: 'Bosnian' },
    { value: 'bul', label: 'Bulgarian' },
    { value: 'cat', label: 'Catalan' },
    { value: 'zho', label: 'Chinese' },
    { value: 'hrv', label: 'Croatian' },
    { value: 'ces', label: 'Czech' },
    { value: 'dan', label: 'Danish' },
    { value: 'nld', label: 'Dutch' },
    { value: 'eng', label: 'English' },
    { value: 'est', label: 'Estonian' },
    { value: 'fin', label: 'Finnish' },
    { value: 'fra', label: 'French' },
    { value: 'glg', label: 'Galician' },
    { value: 'kat', label: 'Georgian' },
    { value: 'deu', label: 'German' },
    { value: 'ell', label: 'Greek' },
    { value: 'guj', label: 'Gujarati' },
    { value: 'heb', label: 'Hebrew' },
    { value: 'hin', label: 'Hindi' },
    { value: 'hun', label: 'Hungarian' },
    { value: 'isl', label: 'Icelandic' },
    { value: 'ind', label: 'Indonesian' },
    { value: 'ita', label: 'Italian' },
    { value: 'jpn', label: 'Japanese' },
    { value: 'kan', label: 'Kannada' },
    { value: 'kaz', label: 'Kazakh' },
    { value: 'kor', label: 'Korean' },
    { value: 'lav', label: 'Latvian' },
    { value: 'lit', label: 'Lithuanian' },
    { value: 'mkd', label: 'Macedonian' },
    { value: 'msa', label: 'Malay' },
    { value: 'mal', label: 'Malayalam' },
    { value: 'mar', label: 'Marathi' },
    { value: 'mon', label: 'Mongolian' },
    { value: 'nep', label: 'Nepali' },
    { value: 'nor', label: 'Norwegian' },
    { value: 'nno', label: 'NorwegianNynorsk' },
    { value: 'fas', label: 'Persian' },
    { value: 'pol', label: 'Polish' },
    { value: 'por', label: 'Portuguese' },
    { value: 'pan', label: 'Punjabi' },
    { value: 'ron', label: 'Romanian' },
    { value: 'rus', label: 'Russian' },
    { value: 'srp', label: 'Serbian' },
    { value: 'sin', label: 'Sinhalese' },
    { value: 'slk', label: 'Slovak' },
    { value: 'slv', label: 'Slovenian' },
    { value: 'som', label: 'Somali' },
    { value: 'spa', label: 'Spanish' },
    { value: 'swa', label: 'Swahili' },
    { value: 'swe', label: 'Swedish' },
    { value: 'tam', label: 'Tamil' },
    { value: 'tel', label: 'Telugu' },
    { value: 'tha', label: 'Thai' },
    { value: 'bod', label: 'Tibetan' },
    { value: 'tur', label: 'Turkish' },
    { value: 'ukr', label: 'Ukrainian' },
    { value: 'urd', label: 'Urdu' },
    { value: 'vie', label: 'Vietnamese' },
];
  

export default languageList