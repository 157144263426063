import * as React from 'react'
import { Layout, message, Tabs, Tag, Button, Modal, Badge, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import Navbar from '../../../components/Navbar/Navbar'
import Documents from './Documents'
import Threads from './Threads'
import Requests from './Requests'
import Insights from './Insights/Insights'
import Settings from './Settings'
import UploadDocs from './UploadDocs'
import ResearchAssitant from './ResearchAssistant'
import News from '../News'
import { refreshToken } from '../../../utils/fetch'
import './CommonsView.css'
// Import And D
const { Content } = Layout

function CommonsView() {
  const [messageApi, contextHolder] = message.useMessage()
  const [activeTab, setActiveTab] = React.useState('documents')
  const [commonsInfo, setCommonsInfo] = React.useState({})
  const [filteredLiterature, setFilteredLiterature] = React.useState([])
  const [selectedRADocuments, setSelectedRADocuments] = React.useState([])
  const { handle } = useParams()
  const [commonsAuth, setCommonsAuth] = React.useState({})
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [commonsAssistantAvailable, setCommonsAssistantAvailable] = React.useState(false)

  const showModal = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    if (info) {
      setIsModalOpen(true)
    } else {
      return (window.location.href = '/')
    }
  }
  const handleOk = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const user = info.user?.id || info.user?.pk
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}request-contributor-access/`, {
      method: 'POST',
      body: JSON.stringify({ user_id: user, commons_id: handle }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setIsModalOpen(false)
        messageApi.open({
          type: 'success',
          content: 'Your request has been made. Check back later.'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error making your request. Please try again.'
        })
      })
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getCommonsAuth = (token, user) => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth-user/`, {
      method: 'POST',
      body: JSON.stringify({ user_id: user, commons_id: handle }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setCommonsAuth(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const getCommonsInfo = () => {
    // Get commons info
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    if (info) {
      const user = info.user?.id || info.user?.pk
      const token = info.access_token
      headers['Authorization'] = `Bearer ${token}`
      getCommonsAuth(token, user)
    }

    fetch(`${process.env.REACT_APP_BASE_URL}commons-info/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: handle }),
      headers
    })
      .then((response) => {
        if (response.status === 401) {
          return refreshToken(getCommonsInfo)
        }
        return response.json()
      })
      .then((data) => {
        // Handle Errors
        if (data?.status === 'error') {
          return (window.location.href = '/')
        }
        // Set Public Info
        setCommonsInfo(data)
        // Get number for requests
        setFilteredLiterature(data.literature.filter((lit) => lit.pending_review === true))
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  React.useEffect(() => {
    getCommonsInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle, activeTab])

  React.useEffect(() => {
    loadCommonsAssistant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handle])

  React.useEffect(() => {
    loadCommonsAssistant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRADocuments])

  const openPrivateFile = (doc) => {
    // Get commons info
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}get-private-doc-url/`, {
      method: 'POST',
      body: JSON.stringify({ file_name: doc.paper_name }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        //  Open up url in a new window
        window.open(data['url'])
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error opening this document. Please try again.'
        })
      })
  }
  const shareCommons = () => {
    const currentUrl = window.location.href
    window.navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Copied. You can now share this link by pasting it anywhere.'
        })
      })
      .catch((err) => {
        console.log(err)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const loadCommonsAssistant = () => {
    let info = JSON.parse(localStorage.getItem('userInfo'))
    // Handle anonomous user
    if (info === null) {
      info = {}
    }
    let user = info.user?.id || info.user?.pk
    if (user === undefined) {
      user = 40
    }
    fetch(`${process.env.REACT_APP_BASE_URL}load-commons-assistant/`, {
      method: 'POST',
      body: JSON.stringify({
        commons_id: handle,
        documents: selectedRADocuments,
        user_id: selectedRADocuments.length > 0 ? user : null
      })
    })
      .then((response) => response.json())
      .then(() => {
        console.log('Making it to here')
        setCommonsAssistantAvailable(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const RequestsIcon = () => {
    return filteredLiterature?.length > 0 ? (
      <Badge offset={[10, -5]} count={filteredLiterature.length}>
        <p style={{ marginBottom: 0 }}>Requests</p>
      </Badge>
    ) : (
      <p style={{ marginBottom: 0 }}>Requests</p>
    )
  }

  const LoadingAssitant = () => {
    return commonsAssistantAvailable === false ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ marginBottom: 0, marginRight: 8 }}>Research Assistant</p>
        <Spin size="small" />
      </div>
    ) : (
      <p style={{ marginBottom: 0 }}>Research Assistant</p>
    )
  }

  return (
    <React.Fragment>
      <Navbar />
      <Content className="explore-container">
        {contextHolder}
        <div className="commons-view-container">
          <Modal
            style={{ zIndex: 10000000 }}
            title="Request Contributor Access"
            visible={isModalOpen}
            onOk={handleOk}
            okText={'Request Access'}
            onCancel={handleCancel}
          >
            <p>
              As a contributor, you will be able to submit requests for documents to be added to this commons space.{' '}
            </p>
          </Modal>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 className="commons-view-title">{commonsInfo?.title}</h1>
            {commonsInfo?.public ? <Tag color="cyan">Public</Tag> : <Tag color="purple">Private</Tag>}
          </div>
          {commonsAuth?.admin || commonsAuth?.maintainer || commonsAuth?.contributor ? null : (
            <Button
              onClick={() => showModal()}
              className="commmons-view-request-contributor-button"
              type="primary"
              size="small"
            >
              Request Contributor Access
            </Button>
          )}
          <Tabs activeKey={activeTab} onChange={(activeTab) => setActiveTab(activeTab)} defaultActiveKey="explore">
            <Tabs.TabPane tab="Resources" key="documents">
              <Documents
                getCommonsInfo={getCommonsInfo}
                messageApi={messageApi}
                commonsAuth={commonsAuth}
                openPrivateFile={openPrivateFile}
                commonsInfo={commonsInfo}
              />
            </Tabs.TabPane>
            {commonsAuth?.admin || commonsAuth?.maintainer || commonsAuth.contributor ? (
              <Tabs.TabPane tab="Threads" key="threads">
                <Threads commonsInfo={commonsInfo} commonsAuth={commonsAuth} messageApi={messageApi} />
              </Tabs.TabPane>
            ) : null}
            {commonsAuth?.admin || commonsAuth?.maintainer ? (
              <Tabs.TabPane tab={<RequestsIcon />} key="requests">
                <Requests getCommonsInfo={getCommonsInfo} messageApi={messageApi} commonsInfo={commonsInfo} />
              </Tabs.TabPane>
            ) : null}
            {commonsAuth?.admin || commonsAuth?.maintainer ? (
              <Tabs.TabPane tab="Upload Resources" key="upload-docs">
                <UploadDocs messageApi={messageApi} commonsInfo={commonsInfo} />
              </Tabs.TabPane>
            ) : null}
            <Tabs.TabPane tab="News Publications" key="news">
              <News messageApi={messageApi} commonsAuth={commonsAuth} commonsInfo={commonsInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<LoadingAssitant />}
              key="assistant"
              disabled={commonsAssistantAvailable === false ? true : false}
            >
              <ResearchAssitant
                setSelectedRADocuments={setSelectedRADocuments}
                selectedRADocuments={selectedRADocuments}
                commonsAuth={commonsAuth}
                messageApi={messageApi}
                commonsInfo={commonsInfo}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Insights" key="toc">
              <Insights commonsAuth={commonsAuth} commonsInfo={commonsInfo} messageApi={messageApi} />
            </Tabs.TabPane>
            {commonsAuth?.admin ? (
              <Tabs.TabPane tab="Settings" key="settings">
                <Settings messageApi={messageApi} commonsInfo={commonsInfo} />
              </Tabs.TabPane>
            ) : null}
          </Tabs>
          {commonsInfo?.public ? (
            <Button onClick={() => shareCommons()} className="commmons-view-share-button" type="link" size="small">
              Share this Commons
            </Button>
          ) : null}
        </div>
      </Content>
    </React.Fragment>
  )
}

export default CommonsView
