import * as React from 'react'
import Chart from './Chart'

function WashPipeline(params) {
  const { data, createRadarData } = params
  const wash_challenges = data[0].data.filter((insight) => insight.insight_type === 'wash_challenges')
  const wash_practices = data[0].data.filter((insight) => insight.insight_type === 'wash_practices')
  const wash_stakeholders = data[0].data.filter((insight) => insight.insight_type === 'wash_stakeholders')
  const wash_education = data[0].data.filter((insight) => insight.insight_type === 'wash_education')
  const wash_policies = data[0].data.filter((insight) => insight.insight_type === 'wash_policies')

  const loadHeatmap = () => {
    const agg_types = ['wash_challenges', 'wash_practices', 'wash_stakeholders', 'wash_education', 'wash_policies']

    agg_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="WASH Related Challenges"
        data={wash_challenges}
        createRadarData={createRadarData}
        description="This component focuses on identifying and categorizing the challenges and obstacles related to water access, sanitation, and hygiene practices. It includes issues such as water scarcity, poor sanitation infrastructure, contamination, and hygiene behavior challenges."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="wash_challenges"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="WASH Practices"
        data={wash_practices}
        createRadarData={createRadarData}
        description="The pipeline seeks to uncover and categorize best practices and interventions in the field of water, sanitation, and hygiene. It includes information on effective water purification methods, sanitation technologies, and hygiene promotion strategies."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="wash_practices"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="WASH Stakeholders"
        data={wash_stakeholders}
        createRadarData={createRadarData}
        description="This aspect identifies and profiles the various stakeholders and actors involved in the WASH sector, including government agencies, NGOs, community-based organizations, international donors, and affected communities."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="wash_stakeholders"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="WASH Education"
        data={wash_education}
        createRadarData={createRadarData}
        description="The pipeline focuses on educational efforts and initiatives related to water, sanitation, and hygiene. It includes programs aimed at raising awareness, promoting behavioral change, and improving hygiene practices in schools and communities."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="wash_education"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="WASH Policies"
        data={wash_policies}
        createRadarData={createRadarData}
        description="This component assesses the policies and regulations governing water, sanitation, and hygiene at local, national, and international levels. It includes information on government policies, legal frameworks, and international agreements related to WASH."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="wash_policies"></div>
      </div>
      {/* -------------------------------------- */}
    </>
  )
}

export default WashPipeline
