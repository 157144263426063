
export const makeRequest = (url, headers, body, refreshFunc ) => {
    fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers
        })
        .then((response) => {
            if (response.status === 401) {
            return refreshToken(refreshFunc)
            }
            return response.json()
        })
}

export const refreshToken = (nextFunc) => {
// Make a request to get a new token
const info = JSON.parse(localStorage.getItem('userInfo'))
const refresh = info.refresh_token
fetch(`${process.env.REACT_APP_BASE_URL}auth/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({
    refresh
    }),
    headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    }
})
    .then((response) => response.json())
    .then((data) => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    info['access_token'] = data.access
    localStorage.setItem('userInfo', JSON.stringify(info))
    return nextFunc()
    })

}
