import * as React from 'react'
import Chart from './Chart'

function ElectionPipeline(params) {
  const { data, createRadarData } = params
  const election_types = data[0].data.filter((insight) => insight.insight_type === 'election_types')
  const election_issues = data[0].data.filter((insight) => insight.insight_type === 'election_issues')
  const election_irregularities = data[0].data.filter((insight) => insight.insight_type === 'election_irregularities')
  const election_entities = data[0].data.filter((insight) => insight.insight_type === 'election_entities')
  const election_technologies = data[0].data.filter((insight) => insight.insight_type === 'election_technologies')

  const loadHeatmap = () => {
    const agg_types = ['election_types', 'election_issues', 'election_irregularities', 'election_technologies']

    agg_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Types of Elections"
        data={election_types}
        createRadarData={createRadarData}
        description="This component classifies and characterizes different types of elections, such as presidential, parliamentary, local, or referendum elections, to provide insights into the nature and purpose of the electoral event."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="election_types"></div>
      </div>
      <Chart
        title="Election Issues"
        data={election_issues}
        createRadarData={createRadarData}
        description="The pipeline aims to uncover the primary issues, topics, and concerns that shape election campaigns and voter decisions, including policy debates, social issues, and economic challenges."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="election_issues"></div>
      </div>
      <Chart
        title="Election Irregularities"
        data={election_irregularities}
        createRadarData={createRadarData}
        description="Identifying election irregularities involves detecting any anomalies or misconduct that may occur during the electoral process, such as voter suppression, fraud, ballot tampering, or other violations of election integrity."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="election_irregularities"></div>
      </div>
      <Chart
        title="Election Entities"
        data={election_entities}
        createRadarData={createRadarData}
        description="This aspect focuses on identifying and profiling the key entities involved in the election process, including political parties, candidates, election commissions, and monitoring organizations."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="election_entities"></div>
      </div>
      <Chart
        title="Election Technologies"
        data={election_technologies}
        createRadarData={createRadarData}
        description="The pipeline seeks to highlight the technological aspects of elections, including the use of electronic voting machines, voter registration systems, and digital platforms for campaign communication and reporting."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="election_technologies"></div>
      </div>
    </>
  )
}

export default ElectionPipeline
