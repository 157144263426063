import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './Navbar.css'
// Ant D Components
import { HeatMapOutlined, DownOutlined } from '@ant-design/icons'
import { Row, Col, Dropdown, Menu } from 'antd'

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  let userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    userInfo = JSON.parse(userInfo)
  }

  const logout = () => {
    localStorage.clear()
    window.location.href = '/login/'
  }
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <p onClick={() => navigate('/user-settings/')}>Account Settings</p>
        },
        {
          key: '2',
          label: <p onClick={() => logout()}>Logout</p>
        }
      ]}
    />
  )
  return (
    <Row style={{ minWidth: 1440 }}>
      <Col span={16} className="main-navbar">
        <div
          style={{ display: 'flex', width: '100%', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => navigate(userInfo? '/explore/' : '/')}
        >
          <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 24, marginRight: 6 }} />{' '}
          <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: 26, marginBottom: 0 }}>Insight Commons</h1>
        </div>
      </Col>
      <Col span={8} className="main-navbar-links">
        {userInfo ? (
          <>
            {currentPath !== '/explore/' || currentPath !== '/search/' ? <p onClick={() => navigate('/explore/')}>Explore</p> : null}

            <Dropdown trigger={['hover', 'click']} overlay={menu}>
              <p>
                {userInfo.user?.email}
                <span style={{ marginLeft: 8 }}>
                  <DownOutlined />
                </span>
              </p>
            </Dropdown>
          </>
        ) : null}
      </Col>
    </Row>
  )
}

export default Navbar
