import * as React from 'react'
import InsightsTable from './InsightsTable'
import NewInsight from './NewInsight'

function Insights(params) {
  const { commonsAuth, messageApi, commonsInfo } = params
  const [needsInsightRefresh, setNeedsInsightRefresh] = React.useState(false)
  return (
    <div className="commons-view-container-content">
      <InsightsTable
        commonsInfo={commonsInfo}
        setNeedsInsightRefresh={setNeedsInsightRefresh}
        needsInsightRefresh={needsInsightRefresh}
        commonsAuth={commonsAuth}
        messageApi={messageApi}
      />
      <NewInsight
        commonsInfo={commonsInfo}
        setNeedsInsightRefresh={setNeedsInsightRefresh}
        needsInsightRefresh={needsInsightRefresh}
        commonsAuth={commonsAuth}
        messageApi={messageApi}
      />
    </div>
  )
}

export default Insights
