import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Button, Checkbox, Form, Input } from 'antd'
import { HeatMapOutlined } from '@ant-design/icons'
import Navbar from '../../components/Navbar/Navbar'
import './auth.css'
const { Content } = Layout

function Login() {
  const [loginError, setLoginError] = React.useState('')
  const navigate = useNavigate()
  const onFinish = async (values) => {
    // Set to lowercase
    values['email'] = values.email.toLowerCase()
    try {
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}auth/login/`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const data = await fetchResponse.json()
      if (data) {
        console.log('non_field_errors' in data)
        if ('email' in data || 'non_field_errors' in data) {
          return setLoginError(Object.values(data)[0][0])
        }
        if ('access_token' in data) {
          localStorage.setItem('userInfo', JSON.stringify(data))
        }
      }
      const info = localStorage.getItem('userInfo')
      if (!('access_token' in JSON.parse(info))) {
        setLoginError('Unable to log in with provided credentials.')
      } else {
        setLoginError('')
        // Redirect to home search
        window.location.href = '/explore/'
      }
    } catch (e) {
      console.log(e)
      setLoginError('Unable to log in with provided credentials.')
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <React.Fragment>
      <Navbar />
      <Content className="auth-background">
        <div className="auth-container">
          <div className="auth-login-container">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 36, marginBottom: 24 }} />
            </div>
            <Form
              name="basic"
              labelCol={{
                span: 24
              }}
              wrapperCol={{
                span: 24
              }}
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    message: 'Please input your username!'
                  }
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    message: 'Please input your password!'
                  }
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  span: 24
                }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  span: 24
                }}
              >
                <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                  Login
                </Button>
                {loginError ? (
                  <p style={{ marginTop: 16, marginBottom: 0 }}>
                    {loginError + ' '}
                    <a href="mailto:support@insightcommons.com" style={{ color: '#13C2C2', cursor: 'pointer' }}>
                      Contact Support{' '}
                    </a>
                    if the issue persists or click{' '}
                    <span style={{ color: '#13C2C2', cursor: 'pointer' }} onClick={() => navigate('/resetpassword')}>
                      here
                    </span>{' '}
                    to reset your password.
                  </p>
                ) : null}
              </Form.Item>
            </Form>
          </div>
          <div className="auth-login-next-container">
            <p>
              New to Insight Commons?{' '}
              <span style={{ color: '#13C2C2', cursor: 'pointer' }} onClick={() => navigate('/register/')}>
                Create an Account
              </span>
            </p>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default Login
