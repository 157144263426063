import * as React from 'react'
import { Typography } from 'antd'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'
const { Title } = Typography

function Chart(params) {
  const { title, data, createRadarData, description } = params
  return (
    <div>
      <Title level={5}>{title}</Title>
      <p>{description}</p>
      <div style={{ height: 450 }}>
        {createRadarData ? (
          <RadarChart cx={550} cy={250} outerRadius={150} width={1065} height={451} data={createRadarData(data)}>
            <PolarGrid />
            <PolarAngleAxis dataKey="title" />
            <PolarRadiusAxis />
            <Radar name={title} dataKey="A" stroke="#000000" fill="#13c2c2" fillOpacity={0.6} />
          </RadarChart>
        ) : null}
      </div>
    </div>
  )
}

function AgriculturePipeline(params) {
  const { data, createRadarData } = params
  const agriculture_types = data[0].data.filter((insight) => insight.insight_type === 'agriculture_types')
  const agriculture_key_crops = data[0].data.filter((insight) => insight.insight_type === 'agriculture_key_crops')
  const agriculture_challenges = data[0].data.filter((insight) => insight.insight_type === 'agriculture_challenges')
  const agriculture_tools = data[0].data.filter((insight) => insight.insight_type === 'agriculture_tools')
  const agriculture_development_services = data[0].data.filter(
    (insight) => insight.insight_type === 'agriculture_development_services'
  )
  const agriculture_sustainability_factors = data[0].data.filter(
    (insight) => insight.insight_type === 'agriculture_sustainability_factors'
  )

  const loadHeatmap = () => {
    const agg_types = [
      'agriculture_types',
      'agriculture_key_crops',
      'agriculture_challenges',
      'agriculture_tools',
      'agriculture_development_services',
      'agriculture_sustainability_factors'
    ]

    agg_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Agricultural Types"
        data={agriculture_types}
        createRadarData={createRadarData}
        description="Identifying agricultural types is crucial as it allows us to understand the diverse range of farming practices, which vary by region and significantly impact crop selection, land use, and resource allocation."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_types"></div>
      </div>
      <Chart
        title="Key Crops"
        data={agriculture_key_crops}
        createRadarData={createRadarData}
        description="Identifying key crops is vital to assess food security and economic stability, as these crops often serve as primary sources of nutrition and income for communities and nations."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_key_crops"></div>
      </div>
      <Chart
        title="Agricultural Challenges"
        data={agriculture_challenges}
        createRadarData={createRadarData}
        description="Recognizing agricultural challenges is essential to address and mitigate obstacles such as pests, climate change, market volatility, and resource scarcity that can threaten food production and livelihoods."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_challenges"></div>
      </div>
      <Chart
        title="Current Technology"
        data={agriculture_tools}
        createRadarData={createRadarData}
        description="Identifying current agricultural technology is important to gauge the efficiency, sustainability, and innovation within the agricultural sector, as technology can greatly influence productivity and resource management."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_tools"></div>
      </div>
      <Chart
        title="Agricultural Services"
        data={agriculture_development_services}
        createRadarData={createRadarData}
        description="Identifying current agricultural technology is important to gauge the efficiency, sustainability, and innovation within the agricultural sector, as technology can greatly influence productivity and resource management."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_development_services"></div>
      </div>
      <Chart
        title="Sustainability Factors"
        data={agriculture_sustainability_factors}
        createRadarData={createRadarData}
        description="Recognizing sustainability factors is critical as they shed light on the environmental and social impacts of agriculture, helping us make informed decisions to promote practices that safeguard the planet's resources while meeting the needs of future generations."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="agriculture_sustainability_factors"></div>
      </div>
    </>
  )
}

export default AgriculturePipeline
