const insightsConfig = {
  outcomes: {
    columns: [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date'
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },

      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'Polarity',
        dataIndex: 'polarity',
        key: 'polarity'
      },
      {
        title: 'Relevance',
        dataIndex: 'relevance',
        key: 'relevance'
      },
      {
        title: 'Relevance Score',
        dataIndex: 'relevancy_score',
        key: 'relevancy_score'
      },
      {
        title: 'Related Document',
        key: 'document_reference_name',
        render: (record) => (
          <a href={record.document_reference_url} target="_blank" rel="noreferrer">
            {record.document_reference_name}
          </a>
        )
      },
      {
        title: 'Related Dataset',
        key: 'dataset_reference_name',
        render: (record) => (
          <a href={record.dataset_reference_url} target="_blank" rel="noreferrer">
            {record.dataset_reference_name}
          </a>
        )
      },
      {
        title: 'Created By',
        dataIndex: 'created_by',
        key: 'created_by'
      }
    ]
  },
  newFormDescriptions: {
    insightType: 'Select the type of insight you wish to add.',
    insightTitle: 'Select an existing title or create a title of your own.',
    insightDescription: 'Add a description to your insight explaining the insight.',
    insightReleanvce: 'Explain why this insight is relevant.',
    insightRelevancyScore: 'On a scale of 1-10, how relevant is this insight?',
    insightDocumentReference: 'Is this insight related to a specific document? If so, select it from the list below.',
    insightDatasetReference: 'Is this insight related to a specific dataset? If so, select it from the list below.',
    insightDate: 'If a date corresponds with this insight, you can add it here.',
    insightLocation: 'If a location corresponds with this insight, you can add it here.',
    insightCountry: 'If a country corresponds with this insight, you can add it here.'
  }
}

export default insightsConfig
