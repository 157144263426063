import * as React from 'react'
import * as XLSX from 'xlsx'
import { Button, Modal, Spin } from 'antd'
import './DatasetResult.css'

function DatasetResult(props) {
  const { result, messageApi, addDocToCommons } = props
  const score = result.score.toString().substring(0, 5)
  const [viewingData, setViewingData] = React.useState(false)
  const [datasetFiles, setDataSetFiles] = React.useState([])
  const [fileKey, setFileKey] = React.useState('')
  const [__html, setHTML] = React.useState('')

  const getDatasetFiles = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}view-dataset-files/`, {
      method: 'POST',
      body: JSON.stringify({
        dataset_id: result.id
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setDataSetFiles(data.data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error fetching the files associated with this dataset. Please try again.'
        })
      })
  }

  React.useEffect(() => {
    if (viewingData === true) {
      getDatasetFiles()
    }
  }, [viewingData])

  const viewDatasetFile = (fileKey) => {
    setFileKey(fileKey)
    fetch(`${process.env.REACT_APP_BASE_URL}view-dataset-file/`, {
      method: 'POST',
      body: JSON.stringify({
        file_key: fileKey
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        data = JSON.parse(data)
        const fileUrl = data.data // Replace with the actual property name containing the URL
        return fetch(fileUrl) // Fetch the file from the URL provided in the response
      })
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const workbook = XLSX.read(arrayBuffer, { sheetRows: 20 })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const table = XLSX.utils.sheet_to_html(worksheet)
        setHTML(table)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error opening this dataset file. Please try again.'
        })
        closeDatasetData()
      })
  }
  const closeDatasetData = () => {
    setFileKey('')
  }

  const handleModalOkay = () => {
    closeDatasetData()
    setViewingData(false)
    addDocToCommons(result.name, result.date_published, result.organization, result)
  }

  return (
    <>
      <Modal
        okText={'Add Dataset to Commons'}
        width={'80%'}
        onOk={() => handleModalOkay()}
        className="search-results-modal-datasets"
        bodyStyle={{ height: '60vh', overflow: 'auto' }}
        title={fileKey ? fileKey : result.name}
        visible={viewingData}
        onCancel={fileKey ? () => closeDatasetData() : () => setViewingData(false)}
        cancelText={fileKey ? 'Back' : 'Close'}
      >
        {fileKey ? (
          <div className="excel-table-style" dangerouslySetInnerHTML={{ __html }} />
        ) : (
          <div style={{ position: 'relative' }}>
            <p style={{ position: 'absolute', right: 0, top: 1 }}>
              View Metadata:{' '}
              <a target="_blank" href={result.metadata_url}>
                {result.metadata_url}
              </a>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 16, marginTop: 0 }}>Associated Files:</p>
            {datasetFiles.length > 0 ? (
              datasetFiles.map((file) => {
                return (
                  <div
                    key={file.Key}
                    style={{
                      borderTop: '1px solid lightgray',
                      borderBottom: '1px solid lightgray',
                      paddingBottom: 16,
                      paddingTop: 16,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <p style={{ fontSize: 14, marginBottom: 0 }}>{file.File_name}</p>
                    <Button onClick={() => viewDatasetFile(file.Key)} type="link" style={{ paddingRight: 0 }}>
                      View File
                    </Button>
                  </div>
                )
              })
            ) : (
              <div style={{ width: '100%', marginTop: 100, textAlign: 'center' }}>
                <Spin className="spinner" size="small" />
              </div>
            )}
          </div>
        )}
      </Modal>
      <div key={result.id} className="search-result-container">
        <a href={result.download_url} target="_blank" rel="noreferrer">
          <Button className="search-result-container-link" size="small" type="link">
            Download from Source
          </Button>
        </a>
        <p className="search-result-container-date-organization">
          {result.last_published.substring(0, 10)} | <span style={{ color: '#13C2C2' }}>{result.organization}</span>
        </p>
        <p className="search-result-container-title">{result.name}</p>
        <p className="search-result-container-details">Relevance Score: {score}</p>
        <p className="search-result-container-details"></p>
        <p className="search-result-container-text-snippet">...{result.highlight_text}...</p>
        <div style={{ textAlign: 'right', marginTop: 32 }}>
          <Button
            onClick={() => addDocToCommons(result.name, result.date_published, result.organization, result)}
            style={{ marginBottom: 16, width: 148 }}
            size="small"
            type="primary"
          >
            Add to Commons
          </Button>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setViewingData(true)}
            style={{ marginBottom: 16, width: 148, color: '#13c2c2' }}
            size="small"
            type="secondary"
          >
            View Data
          </Button>
        </div>
      </div>
    </>
  )
}

export default DatasetResult
