import * as React from 'react'
import { Table, Typography, Tabs, notification } from 'antd'
import { useParams } from 'react-router-dom'
import insightsConfig from '../data/InsightsConfig'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'
import FoodInsecurityPipeline from './PipelineModules/FoodInsecurityPipeline'
import ConflictPipeline from './PipelineModules/ConflictPipeline'
import AgriculturePipeline from './PipelineModules/AgriculturePipeline'
import MigrationPipeline from './PipelineModules/MigrationPipeline'
import ElectionPipeline from './PipelineModules/ElectionPipeline'
import WashPipeline from './PipelineModules/WashPipeline'
import GBVPipeline from './PipelineModules/GBVPipeline'
import { insightPipelines, fixedColumns } from './PipelineModules/insightsData'
const { Title } = Typography

function InsightsTable(params) {
  const { commonsAuth, messageApi, needsInsightRefresh, setNeedsInsightRefresh, commonsInfo } = params
  const { handle } = useParams()
  const [outcomeInsights, setOutcomeInsights] = React.useState([])
  const [rhsInsights, setRHSInsights] = React.useState([])
  const [interventionInsights, setInterventionInsights] = React.useState([])
  const [organizationInsights, setOrganizationInsights] = React.useState([])
  const [locationInsights, setLocationInsights] = React.useState([])
  const [autoInsightTypes, setAutoInsightTypes] = React.useState([])
  // Domain Insights
  const [foodInsecurityInsights, setFoodInsecurityInsights] = React.useState([])
  const [conflictInsights, setConflictInsights] = React.useState([])
  const [agricultureInsights, setAgricultureInsights] = React.useState([])
  const [migrationInsights, setMigrationInsights] = React.useState([])
  const [electionInsights, setElectionInsights] = React.useState([])
  const [WASHInsights, setWASHInsights] = React.useState([])
  const [gender_based_violenceInsights, setgender_based_violenceInsights] = React.useState([])

  const tableInsightTypes = [
    { label: 'Risks, Shocks, and Hazards', data: rhsInsights },
    { label: 'Outcomes', data: outcomeInsights },
    { label: 'Interventions', data: interventionInsights },
    { label: 'Organizations', data: organizationInsights },
    { label: 'Locations', data: locationInsights },
    { label: 'Food Insecurity', data: foodInsecurityInsights },
    { label: 'Conflict', data: conflictInsights },
    { label: 'Agriculture', data: agricultureInsights },
    { label: 'Migration', data: migrationInsights },
    { label: 'Elections', data: electionInsights },
    { label: 'Water, Sanitation, and Hygiene', data: WASHInsights },
    { label: 'Gender Based Violence', data: gender_based_violenceInsights }
  ]

  const getInsights = () => {
    let info = JSON.parse(localStorage.getItem('userInfo'))
    // Handle anonomous user
    if (info === null) {
      info = { user: { id: 40 } }
    }
    if (info) {
      // const token = info.access_token
      fetch(`${process.env.REACT_APP_BASE_URL}retrieve-insights/`, {
        method: 'POST',
        body: JSON.stringify({ commons_id: handle }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
          // Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // Get auto insights from commons insights
          const literature = commonsInfo['literature']
          literature.forEach((document) => {
            data.data = data.data.concat(document['auto_insights'])
          })
          // Add manual insights to insights
          setOutcomeInsights(data.data.filter((insight) => insight.insight_type === 'outcome'))
          setRHSInsights(data.data.filter((insight) => insight.insight_type === 'rhs'))
          setInterventionInsights(data.data.filter((insight) => insight.insight_type === 'intervention'))
          setOrganizationInsights(data.data.filter((insight) => insight.insight_type === 'organization'))
          setLocationInsights(data.data.filter((insight) => insight.insight_type === 'location'))
        })
        .catch((error) => {
          console.log(error)
          messageApi.open({
            type: 'error',
            content: 'There was an error fetching the insight results. Please try again.'
          })
        })
    }
  }

  const getAutomaticInsights = () => {
    let info = JSON.parse(localStorage.getItem('userInfo'))
    // Handle anonomous user
    if (info === null) {
      info = { user: { id: 40 } }
    }
    if (info) {
      const token = info.access_token
      fetch(`${process.env.REACT_APP_BASE_URL}get-commons-automated-insights/`, {
        method: 'POST',
        body: JSON.stringify({ commons_id: handle }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
          // Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // Identify Insight Types
          const insightTypes = new Set()
          const availableTypes = {
            food_insecurity: [
              'food_insecurity_drivers',
              'food_insecurity_vulnerable_populations',
              'food_insecurity_nutiritonal_indicators',
              'food_insecurity_interventions',
              'food_insecurity_impacts'
            ],
            conflict: [
              'conflict_drivers',
              'conflict_events',
              'conflict_gbv',
              'conflict_human_rights',
              'conflict_impacts',
              'conflict_peace_resolutions'
            ],
            agriculture: [
              'agriculture_types',
              'agriculture_key_crops',
              'agriculture_challenges',
              'agriculture_tools',
              'agriculture_development_services',
              'agriculture_sustainability_factors'
            ],
            migration: [
              'migration_types',
              'migration_reasons',
              'migration_challenges',
              'migration_legal_policy_dimensions',
              'migration_stakeholders',
              'migration_impacts'
            ],
            election: [
              'election_types',
              'election_issues',
              'election_irregularities',
              'election_entities',
              'election_technologies'
            ],
            WASH: ['wash_challenges', 'wash_practices', 'wash_stakeholders', 'wash_education', 'wash_policies'],
            gender_based_violence: [
              'gbv_presence_type',
              'gbv_vulnerable_groups',
              'gbv_services_for_survivors',
              'gbv_prevention_intervention',
              'gbv_barriers_to_reporting',
              'gbv_perpetrator_factors',
              'gbv_support_systems',
              'gbv_societal_reactions'
            ]
          }
          data.data.forEach((insight) => {
            // Check if insight_type is one of the available types
            for (const parentType in availableTypes) {
              if (availableTypes[parentType].includes(insight.insight_type)) {
                // Add the parentType to the Set
                insightTypes.add(parentType)
                break // Exit the loop once a match is found
              }
            }
          })
          setAutoInsightTypes(Array.from(insightTypes))
          insightTypes.forEach((type) => {
            const dataArray = []
            const insightTypeData = data.data.filter((insight) => availableTypes[type].includes(insight.insight_type))
            dataArray.push({ label: type, data: insightTypeData })
            switch (type) {
              case 'food_insecurity':
                setFoodInsecurityInsights(dataArray)
                break
              case 'conflict':
                setConflictInsights(dataArray)
                break
              case 'agriculture':
                setAgricultureInsights(dataArray)
                break
              case 'migration':
                setMigrationInsights(dataArray)
                break
              case 'election':
                setElectionInsights(dataArray)
                break
              case 'WASH':
                setWASHInsights(dataArray)
                break
              case 'gender_based_violence':
                setgender_based_violenceInsights(dataArray)
                break
              default:
                break
            }
          })
        })
    }
  }
  const createRadarData = (data) => {
    const titleCounts = {}

    // Iterate through the array
    data.forEach((item) => {
      const title = item.title

      // If the title exists in titleCounts, increment its count; otherwise, initialize it to 1
      if (titleCounts[title]) {
        titleCounts[title]++
      } else {
        titleCounts[title] = 1
      }
    })

    // Convert the titleCounts object into the desired format
    const result = Object.entries(titleCounts).map(([title, count]) => ({
      title: title.length > 40 ? title.substring(0, 37) + '...' : title,
      A: count,
      fullMark: data.length
    }))
    return result.slice(0, 10)
  }

  React.useEffect(() => {
    getInsights()
    getAutomaticInsights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (needsInsightRefresh === true) {
      getInsights()
      setNeedsInsightRefresh(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsInsightRefresh])

  const deleteInsight = (record) => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    if (info && commonsAuth.maintainer === true) {
      const token = info.access_token
      fetch(`${process.env.REACT_APP_BASE_URL}delete-insight/`, {
        method: 'POST',
        body: JSON.stringify({ commons_id: handle, insight_id: record.id, insight_type: record.insight_type }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          messageApi.open({
            type: 'success',
            content: 'Insight Deleted.'
          })
          return getInsights()
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      messageApi.open({
        type: 'error',
        content: 'You do not have the right privledges to delete insights.'
      })
    }
  }

  const getColumns = () => {
    const columns = insightsConfig.outcomes.columns

    const containsDelete = columns.some((obj) => obj.key === 'delete')
    if (!containsDelete && commonsAuth.maintainer === true) {
      columns.push({
        title: 'Delete Insight',
        key: 'delete',
        fixed: 'delete',
        width: 100,
        render: (record) => (
          <p style={{ color: 'red', marginBottom: 0, cursor: 'pointer' }} onClick={() => deleteInsight(record)}>
            Delete
          </p>
        )
      })
    }

    return columns
  }

  const rowSelection = {
    onSelect: (record) => {
      // Remove is already selected
      switch (record['key']) {
        case 'food_insecurity':
          if (foodInsecurityInsights.length > 0) {
            setFoodInsecurityInsights([])
          }
          break
        case 'conflict':
          if (conflictInsights.length > 0) {
            setConflictInsights([])
          }
          break
        case 'agriculture':
          if (agricultureInsights.length > 0) {
            setAgricultureInsights([])
          }
          break
        case 'migration':
          if (migrationInsights.length > 0) {
            setMigrationInsights([])
          }
          break
        case 'election':
          if (electionInsights.length > 0) {
            setElectionInsights([])
          }
          break
        case 'WASH':
          if (WASHInsights.length > 0) {
            setWASHInsights([])
          }
          break
        case 'gender_based_violence':
          if (gender_based_violenceInsights.length > 0) {
            setgender_based_violenceInsights([])
          }
          break

        default:
          break
      }
      // Make request to add commons: this also handles remove on the server
      fetch(`${process.env.REACT_APP_BASE_URL}add-commons-insight-pipeline/`, {
        method: 'POST',
        body: JSON.stringify({ commons_id: handle, insight_type: record['key'] })
      })
        .then((response) => response.json())
        .then((data) => {
          // getAutomaticInsights()
          if (autoInsightTypes.indexOf(record['key']) !== -1) {
            // Remove it
            const newAutoInsightTypes = autoInsightTypes.filter((type) => type !== record['key'])
            setAutoInsightTypes(newAutoInsightTypes)
            messageApi.open({
              type: 'success',
              content: 'Insight Pipeline Removed.'
            })
          } else {
            setAutoInsightTypes([...autoInsightTypes, record['key']])
            messageApi.open({
              type: 'success',
              content: 'Insight Pipeline Added: This can take anywhere between 1 - 5 minutes to see results.'
            })
          }
        })
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  }

  const getInsightComponent = (type) => {
    switch (type) {
      case 'Food Insecurity':
        return <FoodInsecurityPipeline createRadarData={createRadarData} data={foodInsecurityInsights} />
      case 'Conflict':
        return <ConflictPipeline createRadarData={createRadarData} data={conflictInsights} />
      case 'Agriculture':
        return <AgriculturePipeline createRadarData={createRadarData} data={agricultureInsights} />
      case 'Migration':
        return <MigrationPipeline createRadarData={createRadarData} data={migrationInsights} />
      case 'Elections':
        return <ElectionPipeline createRadarData={createRadarData} data={electionInsights} />
      case 'Water, Sanitation, and Hygiene':
        return <WashPipeline createRadarData={createRadarData} data={WASHInsights} />
      case 'Gender Based Violence':
        return <GBVPipeline createRadarData={createRadarData} data={gender_based_violenceInsights} />
      default:
        break
    }
  }

  return (
    <div className="commons-view-main-content" style={{ overflow: 'auto' }}>
      <h2>Insight Models</h2>
      {JSON.parse(localStorage.getItem('userInfo')) !== null ? (
        <>
          <p>Selected the Insights that you are interested in adding to this commons.</p>
          <Table
            columns={fixedColumns}
            dataSource={insightPipelines}
            pagination={false}
            scroll={{
              y: 400
            }}
            bordered
            rowSelection={{
              type: 'checkbox',
              hideSelectAll: true,
              selectedRowKeys: ['rhs', 'interventions', 'organizations', 'locations'].concat(autoInsightTypes),
              ...rowSelection
            }}
          />
        </>
      ) : null}

      <Tabs defaultActiveKey="1" style={{ marginTop: 16 }}>
        {tableInsightTypes.map((type) => {
          return type['data'].length > 0 ? (
            <Tabs.TabPane size={'small'} tab={type['label']} key={type['label']}>
              {type['label'] === 'Risks, Shocks, and Hazards' ||
              type['label'] === 'Interventions' ||
              type['label'] === 'Organizations' ||
              type['label'] === 'Locations' ? (
                <>
                  <Title level={5}>{type['label']}</Title>
                  {type['label'] === 'Risks, Shocks, and Hazards' || type['label'] === 'Interventions' ? (
                    <p>
                      This tool is designed to provide insights by analyzing the content within this Commons for
                      associated {type['label']}. Rather than concentrating solely on specific instances, our model
                      evaluates the underlying content to determine relevant associations.
                    </p>
                  ) : null}

                  <div style={{ height: 450 }}>
                    <RadarChart
                      cx={550}
                      cy={250}
                      outerRadius={150}
                      width={1065}
                      height={451}
                      data={createRadarData(type['data'])}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="title" />
                      <PolarRadiusAxis />
                      <Radar name={type['label']} dataKey="A" stroke="#000000" fill="#13c2c2" fillOpacity={0.6} />
                    </RadarChart>
                  </div>

                  <Table
                    style={{ marginTop: 16 }}
                    rowKey={'id'}
                    bordered={true}
                    columns={getColumns()}
                    dataSource={type['data']}
                  />
                </>
              ) : (
                <>{getInsightComponent(type['label'])}</>
              )}
            </Tabs.TabPane>
          ) : null
        })}
      </Tabs>
    </div>
  )
}

export default InsightsTable
