import * as React from 'react'
import './App.less'
// Import Views
import Login from './views/Auth/Login'
import Explore from './views/Commons/Explore/Explore'
import CommonsView from './views/Commons/CommonsView/CommonsView'
// Aith Views
import Register from './views/Auth/Register'
import UserSettings from './views/Auth/UserSettings'
import PasswordReset from './views/Auth/PasswordRest'
import ResetPassword from './views/Auth/ResetPassword'
import AccountVerification from './views/Auth/AccountVerification'
// Commons Views
import NewCommons from './views/Commons/NewCommons'
// Import Packages
import { Routes, Route } from 'react-router-dom'
// Tour Components
import TourSteps from './views/Commons/Tour/TourSteps'
// Homepage
import Homepage from './views/Homepage/Homepage'
// Business Development
import BusinessDevelopment from './views/UseCases/BusinessDevelopment'
// Import Components
import { Layout } from 'antd'
const { Content } = Layout

function App() {
  const [showingTour, setShowingTour] = React.useState(false)
  let userInfo = localStorage.getItem('userInfo')
  userInfo = JSON.parse(userInfo)
  return (
    <Layout>
      <Layout style={{ background: '#000000' }}>
        <Content style={{ minHeight: '100vh' }}>
          {showingTour ? <TourSteps setShowingTour={setShowingTour} /> : null}
          {userInfo?.access_token && userInfo?.user ? (
            <Routes>
              <Route path="/explore" element={<Explore startingTab={'explore'} />} />
              <Route path="/search" element={<Explore startingTab={'search'} />} />
              <Route path="/topics" element={<Explore startingTab={'topics'} />} />
              <Route path="/commons-home/:handle" element={<CommonsView />} />
              <Route path="/new-commons" element={<NewCommons />} />
              <Route path="/user-settings" element={<UserSettings />} />
              <Route path="/reset/:uidb64/:token/" element={<PasswordReset />} />
              <Route path="/" element={<Explore startingTab={'explore'} />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/auth/registration/account-confirm-email/:key" element={<AccountVerification />} />
              <Route path="/reset/:uidb64/:token/" element={<PasswordReset />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/" element={<Homepage />} />
              <Route path="/use-case/business-dev" element={<BusinessDevelopment />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/commons-home/:handle" element={<CommonsView />} />
            </Routes>
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
