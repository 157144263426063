import * as React from 'react'
import { Layout, message, Input, Button, Typography } from 'antd'
import Navbar from '../../components/Navbar/Navbar'
const { Content } = Layout
const { Title } = Typography

function UserSettings(params) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  const { user } = info
  const [messageApi, contextHolder] = message.useMessage()
  const [emailAddress, setEmailAddress] = React.useState(user.email)
  const [organization, setOrganization] = React.useState(user.organization)
  const [displayName, setDisplayName] = React.useState(user.name)

  const updateUserSettings = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const userId = info.user?.id || info.user?.pk
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}user/update-account/`, {
      method: 'POST',
      body: JSON.stringify({ user_id: userId, email: emailAddress, organization: organization, name: displayName }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        info.user.email = data.user.email
        info.user.organization = data.user.organization
        info.user.name = data.user.name
        localStorage.setItem('userInfo', JSON.stringify(info))
        return messageApi.open({
          type: 'success',
          content: 'Changes Saved'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const sendPasswordReset = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}auth/password/reset/`, {
      method: 'POST',
      body: JSON.stringify({ email: emailAddress }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        return messageApi.open({
          type: 'success',
          content: 'An Email has been sent!'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  return (
    <React.Fragment>
      <Navbar />
      <Content className="explore-container">
        {contextHolder}
        <div className="commons-view-container">
          <div className="commons-view-container-content">
            <div className="commons-view-main-content-settings">
              <div className="commons-view-main-content-settings-container">
                <h1 className="commons-view-main-content-settings-title">Update your User Settings</h1>
                <div className="commons-view-main-thread-divider" />
                <Title style={{ color: 'gray', marginTop: 32 }} level={4}>
                  Profile Details
                </Title>
                <p style={{ marginBottom: 8, marginTop: 16 }}>Email Address</p>
                <Input defaultValue={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                <p style={{ marginBottom: 8, marginTop: 16 }}>Display Name</p>
                <Input defaultValue={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                <p style={{ marginBottom: 8, marginTop: 16 }}>Organization</p>
                <Input defaultValue={organization} onChange={(e) => setOrganization(e.target.value)} />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 16,
                    marginBottom: 24
                  }}
                >
                  <Button onClick={() => updateUserSettings()} type="primary">
                    Update Settings
                  </Button>
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }} className="commons-view-main-thread-divider" />
                <Title style={{ color: 'gray', marginTop: 32 }} level={4}>
                  Reset Password
                </Title>
                <p>Hit the button below and we will send you an email to update your password.</p>
                <Button onClick={() => sendPasswordReset()} type="primary">
                  Send Email
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default UserSettings
