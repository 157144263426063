import * as React from 'react'
import { Tag, Card, Typography, Select, Button } from 'antd'
import { useParams } from 'react-router-dom'
import languageList from './CommonsView/data/news-languages'
const { Title } = Typography

const getHeaders = () => {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  const token = info.access_token
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  headers['Authorization'] = `Bearer ${token}`
  return headers
}

function News(params) {
  const { commonsAuth, messageApi, commonsInfo } = params
  const { handle } = useParams()
  const [articles, setArticles] = React.useState([])
  const [newsTerms, setNewsTerms] = React.useState([])
  const [newsSourceLang, setNewsSourceLang] = React.useState('')

  const getNewsSettings = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}news-ref-info/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: handle }),
      headers: getHeaders()
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle case for no info
        if (data.length === 0) {
          setNewsTerms([])
          setNewsSourceLang('')
        } else {
          setNewsTerms(data[0]['keyword_list'])
          setNewsSourceLang(data[0]['sourcelang'])
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  const getNews = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}get-news/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: handle })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'error') {
          messageApi.open({
            type: 'warning',
            content: 'No news results have been returned.'
          })
          setArticles([])
        } else {
          if ('articles' in data) {
            setArticles(data.articles)
          }
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error fetching the news results. Please try again.'
        })
      })
  }

  React.useEffect(() => {
    if (commonsAuth.admin === true || commonsAuth.maintainer === true) {
      getNewsSettings()
    }
    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveSettings = () => {
    // Fix Terms
    const body = {
      terms: newsTerms,
      sourcelang: newsSourceLang,
      commons_id: commonsInfo.id
    }
    fetch(`${process.env.REACT_APP_BASE_URL}save-commons-news/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: getHeaders()
    })
      .then((response) => response.json())
      .then((data) => {
        getNewsSettings()
        getNews()
        messageApi.open({
          type: 'success',
          content: 'News module settings have been updated.'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content" style={{ overflow: 'auto' }}>
        {commonsAuth.admin === true || commonsAuth.maintainer === true ? (
          <>
            <Title level={4}>NEWS SETTTINGS</Title>
            <Title level={5}>Keyword Phrases</Title>
            <p>
              Add a list of phrases that you wish to appear within a news article. Phrases are seperated from one
              another with an OR clause.
            </p>
            <Select
              mode="tags"
              style={{
                width: 500
              }}
              onChange={(value) => setNewsTerms(value)}
              placeholder="Add Keyword Phrases"
              value={newsTerms}
            />
            <Title style={{ marginTop: 32 }} level={5}>
              Source Language
            </Title>
            <p>Set a source language if you only wish to view news articles in a specfic language.</p>
            <Select
              style={{
                width: 500
              }}
              value={newsSourceLang}
              onChange={(value) => setNewsSourceLang(value)}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={languageList}
            />
            <div style={{ width: '100%', marginTop: 24, marginBottom: 100 }}>
              <Button onClick={() => saveSettings()} type="primary" style={{ width: 200, float: 'right' }}>
                Save Settings
              </Button>
            </div>
          </>
        ) : null}

        <Title style={{ marginTop: 32 }} level={4}>
          RELEVANT NEWS ({articles.length} articles)
        </Title>
        {articles.map((article) => {
          return (
            <Card
              key={article.title}
              style={{
                width: '100%',
                marginTop: 24
              }}
            >
              <div style={{ display: 'flex' }}>
                {article.socialimage ? (
                  <img alt={`${article.title}`} style={{ width: 400, height: 250 }} src={article.socialimage} />
                ) : null}
                <div style={{ marginLeft: 32, fontWeight: 'bold' }}>
                  <p style={{ fontSize: 24 }}>{article.title}</p>
                  <p>Date: {article.seendate}</p>
                  <p>Domain: {article.domain}</p>
                  <p>Source Country: {article.sourcecountry}</p>
                  <p>Source Language: {article.language}</p>
                  <Button type="primary">
                    <a href={article.url} target="_blank" rel="noreferrer">
                      View Article
                    </a>
                  </Button>
                </div>
              </div>
            </Card>
          )
        })}
      </div>
      <div className="commons-view-second-content">
        <p className="commons-view-second-content-title">Summary of Commons</p>
        <p>{commonsInfo?.description}</p>
        <p className="commons-view-second-content-title">Keywords</p>
        <div className="commons-view-second-content-keyword-container">
          {commonsInfo?.themes
            ? commonsInfo?.themes.map((keyword) => {
                return <Tag key={keyword}>{keyword}</Tag>
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default News
