import * as React from 'react'
import { Button, Input } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
const { Search } = Input

function TopCommons(props) {
  const { messageApi } = props
  const [topCommons, setTopCommons] = React.useState([])
  const [searchedCommons, setSearchedCommons] = React.useState([])

  const getTopCommons = (user, token, refreshToken) => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-user-id/`, {
      method: 'POST',
      body: JSON.stringify({ user, ignore_guidance: false }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        // Handle 401 error
        if (response.status === 401) {
          // Make a request to get a new token
          fetch(`${process.env.REACT_APP_BASE_URL}auth/token/refresh/`, {
            method: 'POST',
            body: JSON.stringify({
              refresh: refreshToken
            }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then((data) => {
              const info = JSON.parse(localStorage.getItem('userInfo'))
              info['access_token'] = data.access
              localStorage.setItem('userInfo', JSON.stringify(info))
              return window.location.reload()
            })
        }
        return response.json()
      })
      .then((data) => {
        if (Array.isArray(data)) {
          // Filter so that user guide comes first
          const specificObject = data.find((obj) => obj.title === 'Insight Commons User Guide')
          if (specificObject === undefined) {
            setTopCommons(data)
          } else {
            // Then, filter the rest of the objects without the specific title
            const filteredObjects = data.filter((obj) => obj.title !== 'Insight Commons User Guide')

            // Now, combine the specific object and the filtered objects
            const sortedArray = [specificObject, ...filteredObjects]
            setTopCommons(sortedArray)
          }
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  React.useEffect(() => {
    // Call mounting api calls
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const userId = info.user?.id || info.user?.pk
    const token = info.access_token
    const refreshToken = info.refresh_token
    getTopCommons(userId, token, refreshToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchTopCommons = (value) => {
    if (value === '') {
      setSearchedCommons(topCommons)
    } else {
      const searchResults = topCommons.filter((common) => common.title.toLowerCase().includes(value.toLowerCase()))
      setSearchedCommons(searchResults)
    }
  }
  return (
    <>
      <div className="top-commons-heading">
        <h2>My Top Commons</h2>

        <Button size="small" type="primary">
          <Link to={`/new-commons/`}>
            <PlusOutlined style={{ marginLeft: 0 }} />
            New
          </Link>
        </Button>
      </div>
      <Search
        style={{ marginTop: 16, marginBottom: 16 }}
        placeholder="Find a commons"
        size="small"
        enterButton
        onChange={(e) => searchTopCommons(e.target.value)}
      />
      {topCommons.length === 0
        ? null
        : searchedCommons.length > 0
        ? searchedCommons.map((common) => {
            return (
              <Button
                key={common.id}
                style={{ width: '100%', textAlign: 'left' }}
                className="top-commons-links"
                type="link"
              >
                <Link to={`/commons-home/${common.id}`}>
                  {common.title.length > 42 ? common.title.substring(0, 42) + '...' : common.title}
                </Link>
              </Button>
            )
          })
        : topCommons.map((common) => {
            return (
              <Button
                key={common.id}
                style={{ width: '100%', textAlign: 'left' }}
                className="top-commons-links"
                type="link"
              >
                <Link
                  style={common.title === 'Insight Commons User Guide' ? { fontWeight: 'bold' } : null}
                  to={`/commons-home/${common.id}`}
                >
                  {common.title.length > 42 ? common.title.substring(0, 42) + '...' : common.title}
                </Link>
              </Button>
            )
          })}
    </>
  )
}

export default TopCommons
