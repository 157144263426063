import * as React from 'react'
import { Typography } from 'antd'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'
const { Title } = Typography

function Chart(params) {
  const { title, data, createRadarData, description } = params
  return (
    <div>
      <Title level={5}>{title}</Title>
      <p>{description}</p>
      <div style={{ height: 450 }}>
        {createRadarData ? (
          <RadarChart cx={550} cy={250} outerRadius={150} width={1065} height={451} data={createRadarData(data)}>
            <PolarGrid />
            <PolarAngleAxis dataKey="title" />
            <PolarRadiusAxis />
            <Radar name={title} dataKey="A" stroke="#000000" fill="#13c2c2" fillOpacity={0.6} />
          </RadarChart>
        ) : null}
      </div>
    </div>
  )
}

function FoodInsecurityPipeline(params) {
  const { data, createRadarData } = params
  const food_insecurity_drivers = data[0].data.filter((insight) => insight.insight_type === 'food_insecurity_drivers')
  const food_insecurity_vulnerable_populations = data[0].data.filter(
    (insight) => insight.insight_type === 'food_insecurity_vulnerable_populations'
  )
  const food_insecurity_nutiritonal_indicators = data[0].data.filter(
    (insight) => insight.insight_type === 'food_insecurity_nutiritonal_indicators'
  )
  const food_insecurity_interventions = data[0].data.filter(
    (insight) => insight.insight_type === 'food_insecurity_interventions'
  )
  const food_insecurity_impacts = data[0].data.filter((insight) => insight.insight_type === 'food_insecurity_impacts')

  const loadHeatmap = () => {
    const food_insecurty_types = [
      'food_insecurity_drivers',
      'food_insecurity_vulnerable_populations',
      'food_insecurity_nutiritonal_indicators',
      'food_insecurity_interventions',
      'food_insecurity_impacts'
    ]

    food_insecurty_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Food Insecurity Drivers"
        data={food_insecurity_drivers}
        createRadarData={createRadarData}
        description="Food insecurity drivers refer to the underlying factors and conditions that contribute to or exacerbate the problem of food insecurity in a given population or region."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="food_insecurity_drivers"></div>
      </div>
      <Chart
        title="Vulnerable Populations"
        data={food_insecurity_vulnerable_populations}
        createRadarData={createRadarData}
        description="Vulnerable populations are specific groups or communities, who face increased barriers and challenges in accessing a consistent and nutritious food supply due to factors like poverty, discrimination, or limited access to resources."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="food_insecurity_vulnerable_populations"></div>
      </div>
      <Chart
        title="Nutritional Indicators"
        data={food_insecurity_nutiritonal_indicators}
        createRadarData={createRadarData}
        description="Nutritional indicators are measurable parameters used to assess the nutritional status of individuals or populations, providing essential information on factors like dietary intake, nutrient levels, anthropometric measurements, and health outcomes. These indicators are crucial for evaluating the adequacy of nutrition and identifying areas of concern in order to develop targeted interventions and policies."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="food_insecurity_nutiritonal_indicators"></div>
      </div>
      <Chart
        title="Food Insecurity Interventions"
        data={food_insecurity_interventions}
        createRadarData={createRadarData}
        description="This model is designed to provide insights by analyzing the content within this Commons for
associated interventions related to Food Insecuity. Rather than concentrating solely on specific instances, our model
evaluates the underlying content to determine relevant associations."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="food_insecurity_interventions"></div>
      </div>
      <Chart
        title="Community Impacts"
        data={food_insecurity_impacts}
        createRadarData={createRadarData}
        description="Community impacts of food insecurity encompass a wide range of consequences that affect not only individuals and households but also the broader social fabric."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="food_insecurity_impacts"></div>
      </div>
    </>
  )
}

export default FoodInsecurityPipeline
