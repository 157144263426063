import * as React from 'react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'

function TourSteps(props) {
  const navigate = useNavigate()
  const { setShowingTour } = props
  const [tourStep, setTourStep] = React.useState(1)

  const getTourContent = () => {
    switch (tourStep) {
      case 1:
        return (
          <React.Fragment>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>
              Thanks for checking out Insight Commons!
            </p>
            <p style={{ fontSize: 16 }}>
              Our goal is to democratize knowledge for researchers and practitioners. Insight Commons is a space where
              organizations from small community-based organizations to large UN agencies have the same access to
              essential knowledge resources and the same power to communicate ideas to a broad audience.
            </p>
            <p style={{ fontSize: 16 }}>
              Essential knowledge resources include project documents, evaluations, statistical data, qualitative data,
              guidance, and regulations.
            </p>
            <p style={{ fontSize: 16 }}>
              This tour will walk you through all the things you can do within{' '}
              <span style={{ color: '#13c2c2' }}>Insight Commons</span>.
            </p>
          </React.Fragment>
        )
      case 2:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>What is a commons?</p>
            <p style={{ fontSize: 16 }}>
              A Commons is a knowledge repository that belongs to a community. You contribute to the Commons by adding
              knowledge resources and participating in the dialogue around a specific problem or topic.
            </p>
          </>
        )
      case 3:
        return (
          <>
            {' '}
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>Public and Trending Commons</p>
            <p style={{ fontSize: 16 }}>
              Public Commons are available to all signed-up users. They address themes of general interest, but provide
              the expert with resources to explore a subject in detail.
            </p>
            <p style={{ fontSize: 16 }}>
              A trending Commons is a Public Commons that is in the spotlight, because it is innovative, because it’s
              attracting a lot of attention, or because it addresses an important topic.
            </p>
          </>
        )
      case 4:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>Create a New Commons</p>
            <p style={{ fontSize: 16 }}>
              Go to “My Top Commons” section of the home page, and click on the
              <span style={{ color: '#13c2c2', fontWeight: 'bold' }}>+NEW</span> button to create a new commons.
            </p>
            <p style={{ fontSize: 16 }}>
              You will then be asked to fill out information about your commons space such as a title, description, and
              whether you wish to keep your commons public or make it private.
            </p>
          </>
        )
      case 5:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>How do I Access a Commons?</p>
            <p style={{ fontSize: 16 }}>
              To find a Commons that interests you, hit the Explore tab and all the public Commons will be listed. Click
              on the one that interests you. If you don’t find what you are looking for, try creating a new Commons.
            </p>
            <p style={{ fontSize: 16 }}>
              If you want to add to an existing Commons, select the Commons and click “Request Contributor Access” on
              the top right corner of the screen.
            </p>
          </>
        )
      case 6:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>What’s in a Commons?</p>
            <p style={{ fontSize: 16 }}>
              <span style={{ fontSize: 16, color: '#13c2c2', fontWeight: 'bold' }}>Documents:</span> A list of all
              documents that have been curated for this specific Commons. Threads: A place for members of the Commons
              community to speak, collaborate, and share ideas. Requests: If you are an admin or maintainer of a commons
              space, you can approve or disapprove document requests here.
            </p>
            <p style={{ fontSize: 16 }}>
              <span style={{ fontSize: 16, color: '#13c2c2', fontWeight: 'bold' }}>Threads:</span> A place for users of
              a commons to speak, collaborate, and share ideas.
            </p>
            <p style={{ fontSize: 16 }}>
              <span style={{ fontSize: 16, color: '#13c2c2', fontWeight: 'bold' }}>Requests:</span> If you are an admin
              or maintainer of a commons space, you can approve or disapprove document requests here.
            </p>
            <p style={{ fontSize: 16 }}>
              <span style={{ fontSize: 16, color: '#13c2c2', fontWeight: 'bold' }}>News Publications:</span> A curated
              news stream. Add keywords and get relevant news from over 5000 news sources and 65 different languages.
            </p>
            <p style={{ fontSize: 16 }}>
              <span style={{ fontSize: 16, color: '#13c2c2', fontWeight: 'bold' }}>Settings:</span> As an Admin or
              Maintainer, you will be able to make changes to the Commons settings.
            </p>
          </>
        )
      case 7:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>How do I add new resources?</p>
            <p style={{ fontSize: 16 }}>
              Click the <span style={{ color: '#13c2c2', fontWeight: 'bold' }}>Add New Documents</span> button to search
              for new resources to add to your document repo.
            </p>
          </>
        )
      case 8:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>What can I search for?</p>
            <p style={{ fontSize: 16 }}>
              Use the search to look through over 5 million resources from over 2500+ organizations.
            </p>
            <p style={{ fontSize: 16 }}>
              Once you find a resource that you wish to add to your Commons, you can click the{' '}
              <span style={{ color: '#13c2c2', fontWeight: 'bold' }}>Add to Commons</span> button. Then just select the
              Commons where you want to add it, and hit{' '}
              <span style={{ color: '#13c2c2', fontWeight: 'bold' }}>Save</span>.
            </p>
            <p style={{ fontSize: 16 }}>
              After this, the resource will then be listed in the Requests Table for a Admin or Maintainer to review.
            </p>
          </>
        )
      case 9:
        return (
          <>
            <p style={{ fontSize: 24, color: '#13c2c2', fontWeight: 'bold' }}>Coming Soon…</p>
            <p style={{ fontSize: 16 }}>
              Insight Commons is still in its very early Beta stages. It is growing all the time based on what you, the
              user, wants. We are working on several exciting new suggestions:
            </p>
            <p style={{ fontSize: 16, fontWeight: 'bold' }}>- New and Improved Sources</p>
            <p style={{ fontSize: 16, fontWeight: 'bold' }}>- Kobo Toolbox/ODK and tabular data uploads</p>
            <p style={{ fontSize: 16, fontWeight: 'bold' }}>- Improved Insights</p>
            <p>
              Did you run in to any bugs? Got any great ideas for improving Insight Commons?{' '}
              <a
                href="mailto:support@insightcommons.com"
                style={{ color: '#13C2C2', cursor: 'pointer', fontWeight: 'bold' }}
              >
                Contact Support.
              </a>
            </p>
          </>
        )

      default:
        break
    }
  }

  const nextStep = () => {
    setTourStep(tourStep + 1)
    switch (tourStep + 1) {
      case 6:
        navigate('/commons-home/21')
        break
      case 8:
        navigate('/search/')
        break
      case 9:
        navigate('/explore/')
        break
      case 10:
        // Set User Profile to date of last viewed
        const info = JSON.parse(localStorage.getItem('userInfo'))
        const userId = info.user?.id || info.user?.pk
        const token = info.access_token
        fetch(`${process.env.REACT_APP_BASE_URL}user/update-tour-date/`, {
          method: 'POST',
          body: JSON.stringify({ user_id: userId }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then(() => {
            return setShowingTour(false)
          })
          .catch(() => {
            return setShowingTour(false)
          })

        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <Modal
        style={{ zIndex: 10000000 }}
        visible={true}
        onOk={nextStep}
        okText={tourStep !== 9 ? 'Continue' : 'Finish'}
        onCancel={() => setShowingTour(false)}
      >
        {getTourContent()}
      </Modal>
    </React.Fragment>
  )
}

export default TourSteps
