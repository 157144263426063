import * as React from 'react'
import { Tag, Button, Input, Select } from 'antd'
const { TextArea } = Input

function Settings(params) {
  const { commonsInfo, messageApi } = params
  const [commonsName, setCommonsName] = React.useState(commonsInfo.title)
  const [commonsDescription, setCommonsDescription] = React.useState(commonsInfo.description)
  const [commonsAuthList, setCommonsAuthList] = React.useState([])
  const [commonsAuthRequestList, setCommonsAuthRequestList] = React.useState([])
  const [newUserEmail, setNewUserEmail] = React.useState('')
  const [newUserType, setNewUserType] = React.useState('viewer')

  const authOptions = [
    { label: 'Viewer', value: 'viewer' },
    { label: 'Contributor', value: 'contributor' },
    { label: 'Maintainer', value: 'maintainer' },
    { label: 'Admin', value: 'admin' }
  ]
  const info = JSON.parse(localStorage.getItem('userInfo'))
  const token = info.access_token

  const updateCommonsDetails = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons/${commonsInfo.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({ title: commonsName, description: commonsDescription }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        messageApi.open({
          type: 'success',
          content: 'Update has been saved.'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  React.useEffect(() => {
    getCommonsDetails()
    getCommonsAuthRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCommonsDetails = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth-common/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: commonsInfo.id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setCommonsAuthList(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const getCommonsAuthRequests = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth-request-common/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: commonsInfo.id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        data = data.filter((request) => request.pending_review === true)
        setCommonsAuthRequestList(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error getting contributor requests. Please try again.'
        })
      })
  }

  const getHighestAuth = (user) => {
    if (user.admin === true) {
      return 'admin'
    }
    if (user.maintainer === true) {
      return 'maintainer'
    }
    if (user.contributor === true) {
      return 'contributor'
    }
    if (user.viewer === true) {
      return 'viewer'
    }
    return 'none'
  }

  const updateUserAuth = (value, user) => {
    const newuserDetails = user
    switch (value) {
      case 'viewer':
        newuserDetails['viewer'] = true
        newuserDetails['contributor'] = false
        newuserDetails['maintainer'] = false
        newuserDetails['admin'] = false
        break
      case 'contributor':
        newuserDetails['viewer'] = true
        newuserDetails['contributor'] = true
        newuserDetails['maintainer'] = false
        newuserDetails['admin'] = false
        break
      case 'maintainer':
        newuserDetails['viewer'] = true
        newuserDetails['contributor'] = true
        newuserDetails['maintainer'] = true
        newuserDetails['admin'] = false
        break
      case 'admin':
        newuserDetails['viewer'] = true
        newuserDetails['contributor'] = true
        newuserDetails['maintainer'] = true
        newuserDetails['admin'] = true
        break
      default:
        newuserDetails['viewer'] = false
        newuserDetails['contributor'] = false
        newuserDetails['maintainer'] = false
        newuserDetails['admin'] = false
        break
    }
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth/${user.id}/`, {
      method: 'PATCH',
      body: JSON.stringify(newuserDetails),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        messageApi.open({
          type: 'success',
          content: 'Update has been saved.'
        })
        getCommonsDetails()
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const addNewUserAuth = (options) => {
    let body = {
      admin: false,
      maintainer: false,
      contributor: false,
      viewer: false,
      commons_id: commonsInfo.id,
      user_email: newUserEmail
    }
    if (options) {
      body = options
    }
    body[newUserType] = true
    fetch(`${process.env.REACT_APP_BASE_URL}create-commons-auth-user-email/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (!options) {
          if ('error' in data) {
            return messageApi.open({
              type: 'error',
              content: 'A user with that email does not exist.'
            })
          }
          messageApi.open({
            type: 'success',
            content: 'User has been added to this commons'
          })
        }
        getCommonsDetails()
        setNewUserEmail('')
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  const handleAuthRequest = (authRequest) => {
    // Create new auth record for user
    let body = {
      admin: false,
      maintainer: false,
      contributor: true,
      viewer: true,
      commons_id: commonsInfo.id,
      user_email: authRequest.user_email
    }
    addNewUserAuth(body)
    // Mark existing auth request to no longer pending
    fetch(`${process.env.REACT_APP_BASE_URL}commons-auth-requests/${authRequest.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({ pending_review: false }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        messageApi.open({
          type: 'success',
          content: 'Update has been saved.'
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  const makeCommonsPublic = (commonsId) => {
    const confirmed = window.confirm('Just double checking that you want to make this commons public?')
    if (confirmed) {
      fetch(`${process.env.REACT_APP_BASE_URL}commons/${commonsId}/`, {
        method: 'PATCH',
        body: JSON.stringify({ public: true }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.reload()
          messageApi.open({
            type: 'success',
            content: 'Update has been saved.'
          })
        })
        .catch((error) => {
          messageApi.open({
            type: 'error',
            content: 'There was an error. Please try again.'
          })
        })
    }
  }
  const deleteCommons = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}delete-commons/`, {
      method: 'POST',
      body: JSON.stringify({ commons_id: commonsInfo.id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((success) => {
        if (success['status'] === 'success') {
          messageApi.open({
            type: 'success',
            content: 'This commons space has been deleted.'
          })
          setTimeout(function (x) {
            return (window.location.href = '/explore/')
          }, 2500)
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error deleting this commons space. Please try again.'
        })
      })
  }
  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content-settings">
        <div className="commons-view-main-content-settings-container">
          {commonsInfo.public === false ? (
            <>
              <p style={{ fontWeight: 'bold', fontSize: 16, marginTop: 16 }}>
                It looks like this commons is marked as Private. Why not share it with the Public?
              </p>
              <Button onClick={() => makeCommonsPublic(commonsInfo.id)} style={{ marginBottom: 32 }} type="primary">
                Make Public
              </Button>
            </>
          ) : null}
          <h1 className="commons-view-main-content-settings-title">Edit the Summary of this Commons</h1>
          <p>
            The settings page allows you to customize and manage various aspects of your commons space, such as its
            name, description, and user privileges. You can also handle contributor requests and maintain control over
            the content and users within your commons.
          </p>
          <div className="commons-view-main-thread-divider" />
          <p>Commons Name</p>
          <Input value={commonsName} onChange={(e) => setCommonsName(e.target.value)} />
          <p style={{ marginTop: 70 }}>Commons Description</p>
          <TextArea value={commonsDescription} onChange={(e) => setCommonsDescription(e.target.value)} rows={4} />
          <Button
            onClick={() => updateCommonsDetails()}
            type="primary"
            style={{ float: 'right', width: 132, marginTop: 16, marginBottom: 32 }}
          >
            Update
          </Button>
          <div style={{ marginTop: 70 }} className="commons-view-main-thread-divider" />
          <h1 className="commons-view-main-content-settings-title" style={{ marginTop: 16 }}>
            Edit this Commons Users
          </h1>
          <div className="commons-view-main-content-settings-user-table">
            <div className="commons-view-main-content-settings-user-table-title">
              <p>{commonsInfo.title}</p>{' '}
              {commonsInfo?.public ? <Tag color="cyan">Public</Tag> : <Tag color="purple">Private</Tag>}
            </div>
            {commonsAuthList.map((user) => {
              if (getHighestAuth(user) === 'none') {
                return null
              }
              return (
                <div key={user.commons_id_id} className="commons-view-main-content-settings-user-table-user">
                  <p>{user.user_name}</p>
                  <Select
                    onChange={(value) => updateUserAuth(value, user)}
                    defaultValue={getHighestAuth(user)}
                    style={{ width: 145 }}
                    options={authOptions}
                  />
                  <Button onClick={() => updateUserAuth('', user)} style={{ color: 'red' }} type="link">
                    Remove
                  </Button>
                </div>
              )
            })}
          </div>
          <h3 style={{ marginTop: 16, fontWeight: 'bold' }}>Add New User</h3>
          <p>Enter a user’s email to add them to this common.</p>
          <div style={{ display: 'flex' }}>
            {' '}
            <Input onChange={(e) => setNewUserEmail(e.target.value)} />{' '}
            <Select
              onChange={(value) => setNewUserType(value)}
              defaultValue={newUserType}
              style={{ width: 145 }}
              options={authOptions}
            />{' '}
          </div>
          <Button onClick={() => addNewUserAuth()} type="primary" style={{ float: 'right', width: 132, marginTop: 16 }}>
            Add User
          </Button>
          <h3 style={{ marginTop: 64, fontWeight: 'bold' }}>Handle Contributor Requests</h3>
          <div className="commons-view-main-content-settings-user-table">
            <div className="commons-view-main-content-settings-user-table-title">
              <p>{commonsInfo.title}</p>{' '}
              {commonsInfo?.public ? <Tag color="cyan">Public</Tag> : <Tag color="purple">Private</Tag>}
            </div>
            {commonsAuthRequestList.map((user) => {
              return (
                <div key={user.user_name} className="commons-view-main-content-settings-user-table-user">
                  <p>{user.user_name}</p>
                  <p>{user.user_organization}</p>
                  <Button onClick={() => handleAuthRequest(user)} style={{ color: 'green' }} type="link">
                    Accept
                  </Button>
                </div>
              )
            })}
          </div>

          <h3 style={{ marginTop: 64, fontWeight: 'bold' }}>Danger Zone</h3>
          <p>Deleting a commons is an irreversible action, so please proceed with deletion only if you are certain.</p>
          <Button onClick={() => deleteCommons()} type="danger" style={{ marginTop: 16 }}>
            Delete Commons
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Settings
