const fixedColumns = [
  {
    title: 'Insight Pipeline',
    dataIndex: 'name',
    fixed: true,
    width: 225,
    filterMode: 'tree'
  },
  {
    title: 'Pipeline Description',
    dataIndex: 'description'
  }
]

const insightPipelines = [
  {
    key: 'rhs',
    name: 'Risks, Shocks, and Hazards',
    description:
      'This model is designed to identify and assess risks, shocks, and hazards specific to the international development sector within textual documents, providing valuable insights to mitigate potential challenges and enhance decision-making processes.'
  },
  {
    key: 'interventions',
    name: 'Interventions',
    description:
      'This model excels at pinpointing effective interventions within documents related to international development, streamlining decision-making processes and resource allocation.'
  },
  {
    key: 'organizations',
    name: 'Organizations',
    description: 'The organizational model identifies organizations mentioned within the content of this commons.'
  },
  {
    key: 'locations',
    name: 'Locations',
    description: 'This model identifies all country locations mentioned in the content.'
  },
  {
    key: 'food_insecurity',
    name: 'Food Insecurity',
    description:
      'This model is designed to identify key insights within the field of food insecurity. These insights encompass various aspects, including factors driving food insecurity, vulnerable populations affected, nutritional indicators, and more.'
  },
  {
    key: 'conflict',
    name: 'Conflict',
    description:
      'The Conflict Pipeline is designed to uncover key insights within the domain of conflict analysis. These insights encompass various aspects, including conflict drivers, conflict events, gender-based violence, human rights violations, community impacts of conflict, and peace resolutions.'
  },
  {
    key: 'agriculture',
    name: 'Agriculture',
    description:
      'The Agriculture Pipeline specializes in identifying essential components of agriculture, including agricultural types, key crops, agricultural challenges, current technology, agricultural services, and sustainability factors. This comprehensive approach provides a concise yet comprehensive view of agricultural landscapes for informed decision-making.'
  },
  {
    key: 'migration',
    name: 'Migration',
    description:
      'The Migration Insight Pipeline is designed to extract and categorize key information from migration reports, offering a comprehensive understanding of migration phenomena.'
  },
  {
    key: 'election',
    name: 'Elections',
    description:
      'The Elections Analysis Pipeline is designed to extract and categorize critical information from election reports, facilitating a comprehensive understanding of the electoral process.'
  },
  {
    key: 'WASH',
    name: 'Water, Sanitation, and Hygiene',
    description:
      'The Water, Sanitation, and Hygiene (WASH) Insights Pipeline is designed to extract and categorize crucial information from reports and data related to water, sanitation, and hygiene, providing a comprehensive understanding of the WASH sector.'
  },
  {
    key: 'gender_based_violence',
    name: 'Gender Based Violence',
    description:
      'The Gender Based Violence Pipeline is designed to uncover critical aspects related to Gender-Based Violence (GBV), including the various types of GBV, vulnerable groups affected, available services for survivors, preventive interventions, barriers to reporting, support systems in place, and societal reactions.'
  }
]

export { fixedColumns, insightPipelines }
