import * as React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

function TopicsContent(props) {
  const { searchCommons } = props
  return (
    <>
      <div className="explore-content-container">
        <div className="explore-content-commons-list">
          {searchCommons.length === 0
            ? 'Please refine your search.'
            : searchCommons.map((commons) => {
                return (
                  <div className="commons-list-container">
                    <div className="commons-list-title-container">
                      <p>{commons.title}</p>
                      <Button type="link">
                        <Link to={`/commons-home/${commons.id}`}>View Commons</Link>
                      </Button>
                    </div>
                    <div className="commons-list-content-container">{commons.description}</div>
                  </div>
                )
              })}
        </div>
      </div>
    </>
  )
}

export default TopicsContent
