import * as React from 'react'
import { Tag, Button, Input, Typography, Spin } from 'antd'
import { FileTextOutlined, DeleteOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './CommonsView.css'
const { Search } = Input
const { Title } = Typography

function Documents(params) {
  const { commonsInfo, openPrivateFile, commonsAuth, messageApi, getCommonsInfo } = params
  const [internalSearchResults, setInternalSearchResults] = React.useState([])
  const deleteLiterature = (doc) => {
    // Make request to set literature doc as not actice
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    const confirmed = window.confirm('Are you sure you want to delete this document?')
    if (confirmed === true) {
      fetch(`${process.env.REACT_APP_BASE_URL}remove-literature-to-commons/`, {
        method: 'POST',
        body: JSON.stringify({
          id: doc.id,
          dataset: doc.dataset,
          private: doc.document_url === 'private' ? true : false,
          commons_id: commonsInfo.id
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data['status'] === 'success') {
            getCommonsInfo()
            messageApi.open({
              type: 'success',
              content: `${doc.paper_name} has been removed.`
            })
          } else {
            messageApi.open({
              type: 'error',
              content: 'There was an error. Please try again.'
            })
          }
        })
        .catch(() => {
          messageApi.open({
            type: 'error',
            content: 'There was an error. Please try again.'
          })
        })
    }
  }
  const searchWithinCommons = (search) => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    if (search === '') {
      return setInternalSearchResults([])
    }
    let postgres_ids = commonsInfo.literature.map((obj) => obj.literature_id_id)
    postgres_ids = postgres_ids.filter((element) => element !== undefined)
    fetch(`${process.env.REACT_APP_BASE_URL}query/`, {
      method: 'POST',
      body: JSON.stringify({
        search,
        content_type: [],
        theme: [],
        country: [],
        organization: [],
        most_recent: false,
        postgres_ids: postgres_ids,
        commons_id: commonsInfo.id,
        datasets: false
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const results = data.results.map((result) => result[0])
        // Handle 0 returned results
        if (results.length === 0) {
          return messageApi.open({
            type: 'warning',
            content: 'Your search did not yield any results.'
          })
        }
        setInternalSearchResults(results)
        messageApi.open({
          type: 'success',
          content: `Search Complete.`
        })
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error during the search.'
        })
      })
  }

  const missingInsights = () => {
    if ('literature' in commonsInfo) {
      const missingSummaries = commonsInfo.literature.filter((lit) => lit.summary?.length === 0 && lit.active === true)
      if (missingSummaries.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const generateMissingInsights = () => {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    const missingSummaries = commonsInfo.literature.filter((lit) => lit.summary.length === 0 && lit.active === true)
    fetch(`${process.env.REACT_APP_BASE_URL}generate-commons-insights/`, {
      method: 'POST',
      body: JSON.stringify({
        commons_id: commonsInfo.id,
        papers: missingSummaries
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then(() => {
        messageApi.open({
          type: 'success',
          content:
            "We've started analyzing insights for all the documents in your commons. This process might take anywhere from 2 to 10 minutes depending on Commons size."
        })
      })
      .catch((e) => {
        messageApi.open({
          type: 'error',
          content: 'An error has occured. Is the problem persists, please contact support at support@insightcommons.com'
        })
      })
  }

  const addLiterature = (rec) => {
    const body = {
      commons_id: commonsInfo.id,
      paper_name: rec['paper_name'],
      justification: '',
      needs_review: false,
      result: { postgres_id: rec['postgres_id'], document_url: rec['document_url'] },
      dataset: false
    }
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}add-literature-to-commons/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        getCommonsInfo()
        messageApi.open({
          type: 'success',
          content: 'Added Document to Commons'
        })
      })
      .catch((error) => {
        console.log(error)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }

  // Filter commons literature if it exists
  if (commonsInfo['literature']) {
    const sortedArray = commonsInfo.literature.sort((a, b) => {
      const dateA = new Date(a.date_published)
      const dateB = new Date(b.date_published)
      return dateB - dateA
    })
    commonsInfo.literature = sortedArray
  }

  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content">
        {/* Results Search */}
        <Search
          placeholder="Search within this commons"
          onSearch={(value) => searchWithinCommons(value)}
          style={{
            width: 300,
            position: 'absolute',
            top: 8,
            right: 0
          }}
          onPressEnter={(e) => searchWithinCommons(e.target.value)}
        />
        {commonsAuth?.admin || commonsAuth?.maintainer || commonsAuth?.contributor ? (
          <Link to={`/search/`}>
            <Button style={{ height: 30 }} className="commmons-view-add-doc-button" type="primary" size="small">
              Add New Resources
            </Button>
          </Link>
        ) : null}
        <div className="commons-view-document-table">
          <div className="commons-view-document-table-title">
            <div style={{ display: 'flex' }}>
              <p>{commonsInfo?.title}</p>
              {commonsInfo?.public ? <Tag color="cyan">Public</Tag> : <Tag color="purple">Private</Tag>}
            </div>
            {/* {missingInsights() ? (
              <Button onClick={() => generateMissingInsights()} type="link" style={{ fontWeight: 600 }}>
                Generate Document Insights
              </Button>
            ) : null} */}
          </div>
          {/* Results */}
          {commonsInfo?.literature ? (
            commonsInfo?.literature.map((doc) => {
              if (doc.pending_review === true || doc.active === false) {
                return null
              }
              if (internalSearchResults.length > 0) {
                if (internalSearchResults.includes(doc.paper_name) === false) {
                  return null
                }
              }
              let summary = doc.summary[0]?.summary || ''
              // Handle summary for datasets
              if ('metadata_url' in doc) {
                summary = (
                  <p style={{ marginTop: 8 }}>
                    View Metadata: <a href={doc.metadata_url}>{doc.metadata_url}</a>
                  </p>
                )
              }

              return (
                <div key={doc.paper_name}>
                  <div className="commons-view-document-table-result">
                    <div className="commons-view-document-table-result-column1">
                      {doc.document_url === 'private' ? (
                        <p onClick={() => openPrivateFile(doc)}>
                          <FileTextOutlined style={{ color: '#13C2C2', marginRight: 4 }} />{' '}
                          {doc.document_url === 'private'
                            ? doc.paper_name.length > 70
                              ? doc.paper_name.substring(3, 70) + '...'
                              : doc.paper_name.substring(3, doc.paper_name.length - 4)
                            : doc.paper_name.length > 70
                            ? doc.paper_name.substring(0, 70) + '...'
                            : doc.paper_name}
                          {commonsAuth.maintainer === true || commonsAuth.admin === true ? (
                            <DeleteOutlined
                              onClick={() => deleteLiterature(doc)}
                              style={{ color: 'red', marginLeft: 4, cursor: 'pointer' }}
                            />
                          ) : null}
                        </p>
                      ) : (
                        <a href={doc.document_url} target="_blank" rel="noreferrer">
                          <p style={summary ? { fontWeight: 'bold' } : null}>
                            <FileTextOutlined style={{ color: '#13C2C2', marginRight: 4 }} />{' '}
                            {doc.paper_name.length > 100 ? doc.paper_name.substring(0, 100) + '...' : doc.paper_name}
                          </p>
                        </a>
                      )}
                    </div>
                    <div className="commons-view-document-table-result-two">
                      <p>
                        <span style={{ color: '#13C2C2', textTransform: 'capitalize' }}>
                          {' '}
                          {doc.organization.length > 30 ? doc.organization.substring(0, 30) + '...' : doc.organization}
                        </span>{' '}
                        | {doc.date_published.substring(0, 10)}{' '}
                        {commonsAuth.maintainer === true || commonsAuth.admin === true ? (
                          <DeleteOutlined
                            onClick={() => deleteLiterature(doc)}
                            style={{ color: 'red', marginLeft: 4, cursor: 'pointer' }}
                          />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <p style={{ paddingLeft: 16, paddingRight: 16 }}>{summary}</p>
                </div>
              )
            })
          ) : (
            <div style={{ width: '100%', textAlign: 'center', height: 340, paddingTop: 100 }}>
              <Spin size="large" />
              <Title style={{ color: 'gray', marginTop: 16 }} level={5}>
                LOADING COMMONS INFO
              </Title>
            </div>
          )}
        </div>
      </div>
      <div className="commons-view-second-content">
        <p className="commons-view-second-content-title">Commons Details</p>
        <p>{commonsInfo?.description}</p>
        <p className="commons-view-second-content-title">Keywords</p>
        <div className="commons-view-second-content-keyword-container">
          {commonsInfo?.themes
            ? commonsInfo?.themes.map((keyword) => {
                return <Tag key={keyword}>{keyword}</Tag>
              })
            : null}
        </div>
        {commonsAuth?.admin || commonsAuth?.maintainer ? (
          <p className="commons-view-second-content-title" style={{ marginTop: 8 }}>
            Recommended Documents
          </p>
        ) : null}

        {commonsInfo?.reccomendations && (commonsAuth?.admin || commonsAuth?.maintainer)
          ? commonsInfo?.reccomendations.map((rec) => {
              return (
                <div
                  key={rec.paper_name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #dcdcdc',
                    marginBottom: 16,
                    justifyContent: 'space-between'
                  }}
                >
                  <div>
                    <p style={{ marginBottom: 0, color: '#808080' }}>{rec.date_published.substring(0, 10)}</p>
                    <a href={rec.document_url}>{rec.paper_name}</a>
                    <p>{rec.organization}</p>
                  </div>
                  <Button
                    onClick={() => addLiterature(rec)}
                    style={{ marginBottom: 14 }}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                  />
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default Documents
