import * as React from 'react'
import { Input, InputNumber, Select, DatePicker, Button, Tooltip } from 'antd'
import { useParams } from 'react-router-dom'
import insightsConfig from '../data/InsightsConfig'
import { InfoCircleOutlined } from '@ant-design/icons'
const { TextArea } = Input

function NewInsight(params) {
  const { commonsAuth, messageApi, commonsInfo, setNeedsInsightRefresh } = params
  const { handle } = useParams()
  const [documentOptions, setDocumentOptions] = React.useState([])
  const [datasetOptions, setDatasetOptions] = React.useState([])
  const [addingInsight, setAddingInsight] = React.useState(false)
  const [newInsightType, setNewInsightType] = React.useState('')
  const [taxonomy, setTaxonomy] = React.useState([])
  const [countries, setCountries] = React.useState([])
  // State for form fields
  const [newInsightTitle, setNewInsightTitle] = React.useState('')
  const [newInsightDescription, setNewInsightDescription] = React.useState('')
  const [newInsightPolarity, setNewInsightPolarity] = React.useState('')
  const [newInsightRelevance, setNewInsightRelevance] = React.useState('')
  const [newInsightRelevancyScore, setNewInsightRelevancyScore] = React.useState(5)
  const [newInsightDocumentRef, setNewInsightDocumentRef] = React.useState('')
  const [newInsightDatasetRef, setNewInsightDatasetRef] = React.useState('')
  const [newInsightDate, setNewInsightDate] = React.useState('')
  const [newInsightLocation, setNewInsightLocation] = React.useState('')
  const [newInsightCountry, setNewInsightCountry] = React.useState('')

  const saveInsight = () => {
    const body = {
      title: newInsightTitle[0],
      description: newInsightDescription,
      polarity: newInsightPolarity,
      relevance: newInsightRelevance,
      relevancy_score: newInsightRelevancyScore,
      date: newInsightDate,
      location: newInsightLocation,
      country: newInsightCountry,
      commons_id: handle,
      insight_type: newInsightType,
      document_reference: newInsightDocumentRef,
      dataset_reference: newInsightDatasetRef
    }
    // Check for missing values
    if (!newInsightDate || !newInsightTitle || !newInsightCountry) {
      return messageApi.open({
        type: 'error',
        content: 'Title, Date, and Country are all require fields to create a new insight.'
      })
    }
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const token = info.access_token
    fetch(`${process.env.REACT_APP_BASE_URL}create-insight/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((status) => {
        messageApi.open({
          type: 'success',
          content: 'A new insight has been created.'
        })
        setAddingInsight(false)
        return setNeedsInsightRefresh(true)
      })
      .catch((error) => {
        return messageApi.open({
          type: 'error',
          content: `An error has occurred: ${error}`
        })
      })
  }

  const getTaxonomy = () => {
    let url = ''
    switch (newInsightType) {
      case 'outcome':
        url = 'outcome-taxonomy/'
        break
      case 'intervention':
        url = 'intervention-taxonomy/'
        break
      case 'rhs':
        url = 'rhs-taxonomy/'
        break
      case 'organization':
        url = 'get-organizations/'
        break
      default:
        setTaxonomy([])
        return
    }
    // Make a fetch call for taxonomy
    fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (newInsightType === 'organization') {
          data.organizations.forEach((org) => {
            org.label = org.organization
            org.value = org.organization
          })
          setTaxonomy(data.organizations)
        } else {
          data.data.forEach((option) => {
            option.label = option.description?.length > 0 ? `${option.title} - ${option.description}` : option.title
            option.value = option.title
          })
          setTaxonomy(data.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    getTaxonomy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInsightType.length])

  const getDocumentDatasetOptions = () => {
    let documents = commonsInfo.literature.filter((document) => document.dataset === false && document.active === true)
    documents.forEach((document) => {
      document.value = document.literature_id_id
      document.label = document.paper_name
    })
    setDocumentOptions(documents)
    const datasets = commonsInfo.literature.filter((document) => document.dataset === true && document.active === true)
    datasets.forEach((dataset) => {
      dataset.value = dataset.dataset_id_id
      dataset.label = dataset.paper_name
    })
    setDatasetOptions(datasets)
  }

  const getCountryOptions = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}countries-taxonomy/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const formattedData = []
        data.data.forEach((option) => {
          const newOption = {}
          newOption.label = option[1]
          newOption.value = option[0]
          formattedData.push(newOption)
        })
        setCountries(formattedData)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    getDocumentDatasetOptions()
    getCountryOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return commonsAuth.maintainer === true ? (
    <div className="commons-view-second-content">
      <p className="commons-view-second-content-title">Add a New Insight</p>

      {addingInsight === true ? (
        <>
          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Insight Type{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightType}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Select
            options={[
              { label: 'Outcome', value: 'outcome' },
              { label: 'Risk, Hazard, or Shocks', value: 'rhs' },
              { label: 'Intervention', value: 'intervention' },
              { label: 'Indicator', value: 'indicator' },
              { label: 'Event', value: 'event' },
              { label: 'Organization', value: 'organization' }
            ]}
            style={{ width: '100%' }}
            showSearch
            placeholder="Outcome, Risks, Hazards, Shocks, Intervention, etc."
            onChange={(value) => setNewInsightType(value)}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Title{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightTitle}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Select
            mode="tags"
            onChange={(value) => setNewInsightTitle(value)}
            maxTagCount={1}
            options={taxonomy.length > 0 ? taxonomy : []}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            dropdownMatchSelectWidth={1000}
            placeholder="Title"
            notFoundContent="Please enter your own title."
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Description{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightDescription}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <TextArea
            onChange={(e) => setNewInsightDescription(e.target.value)}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
            rows={4}
          />
          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Polarity{' '}
          </p>
          <Select
            options={[
              { label: 'Negative', value: 'negative' },
              { label: 'Neutral', value: 'neutral' },
              { label: 'Positive', value: 'positive' }
            ]}
            style={{ width: '100%' }}
            showSearch
            placeholder="Negative, Neatural, or Positive"
            onChange={(value) => setNewInsightPolarity(value)}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Relevance{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightReleanvce}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <TextArea
            onChange={(e) => setNewInsightRelevance(e.target.value)}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
            rows={4}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Relevance Score{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightRelevancyScore}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <InputNumber
            onChange={(value) => setNewInsightRelevancyScore(value)}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
            min={1}
            max={10}
            defaultValue={5}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Document Reference{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightDocumentReference}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Select
            disabled={newInsightType && documentOptions.length > 0 ? false : true}
            onChange={(value) => setNewInsightDocumentRef(value)}
            style={{ width: '100%' }}
            showSearch
            placeholder="Select a Document"
            options={documentOptions}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Dataset Reference{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightDatasetReference}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Select
            disabled={newInsightType && datasetOptions.length > 0 ? false : true}
            onChange={(value) => setNewInsightDatasetRef(value)}
            style={{ width: '100%' }}
            showSearch
            placeholder="Select a Dataset"
            options={datasetOptions}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Date{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightDate}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <DatePicker
            onChange={(_, dateString) => setNewInsightDate(dateString)}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Location{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightLocation}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Input
            onChange={(e) => setNewInsightLocation(e.target.value)}
            disabled={newInsightType ? false : true}
            style={{ width: '100%' }}
            placeholder="Location"
          />

          <p style={{ marginTop: 12 }} className="mobile-seach-filters-tree-titles">
            Country{' '}
            <Tooltip placement="top" title={insightsConfig.newFormDescriptions.insightCountry}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          <Select
            onChange={(value) => setNewInsightCountry(value)}
            disabled={newInsightType ? false : true}
            options={countries}
            style={{ width: '100%' }}
            showSearch
            placeholder="Select a Country"
          />
          <Button
            onClick={() => saveInsight()}
            disabled={newInsightType ? false : true}
            type="primary"
            style={{ width: '100%', marginTop: 16 }}
          >
            SAVE INSIGHT
          </Button>
        </>
      ) : (
        <>
          <p>
            If you come across an insight that you believe should be included in this commons but don't see it, feel
            free to add it yourself.
          </p>
          <Button onClick={() => setAddingInsight(true)} type="primary">
            Add Insight
          </Button>
        </>
      )}
    </div>
  ) : null
}

export default NewInsight
