import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Input, Button, Alert, Spin, Avatar, Checkbox, Divider } from 'antd'
import { HeatMapOutlined } from '@ant-design/icons'
const { TextArea } = Input

function ResearchAssitant(params) {
  const { messageApi, commonsInfo, setSelectedRADocuments, selectedRADocuments } = params
  const { handle } = useParams()
  const [newQuestion, setnewQuestion] = React.useState('')
  const [chatHistory, setChatHistory] = React.useState([])
  const [answer, setAnswer] = React.useState({ result: '', sources: [] })
  const [processingAnswer, setProcessingAnswer] = React.useState(false)
  let info = JSON.parse(localStorage.getItem('userInfo'))
  if (info === null) {
    info = {}
  }
  let user = info.user?.id || info.user?.pk
  if (user === undefined) {
    user = 40
  }

  React.useEffect(() => {
    getChatHistory()
  }, [])

  const getChatHistory = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}get-chat-history/`, {
      method: 'POST',
      body: JSON.stringify({
        commons_id: handle,
        user_id: user
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.length > 0) {
          setChatHistory(data.data)
          const scrollHeight = scrollRef.current.scrollHeight
          scrollRef.current.scrollTop = scrollHeight
        }
      })
      .catch((error) => {
        console.log(error)
        setProcessingAnswer(false)
        messageApi.open({
          type: 'error',
          content: 'There was an error obtaining the chat history. Please resubmit your question.'
        })
      })
  }

  const askQuestion = () => {
    setProcessingAnswer(true)
    let newChatHistory = chatHistory
    if (answer['result'] !== '') {
      newChatHistory[newChatHistory.length - 1][1] = answer['result']
      setAnswer({ result: '', sources: [] })
    }
    setChatHistory(newChatHistory.concat([[newQuestion, '']]))
    fetch(`${process.env.REACT_APP_BASE_URL}question-commons-assistant/`, {
      method: 'POST',
      body: JSON.stringify({
        question: newQuestion,
        commons_id: handle,
        user_id: user,
        use_personal: selectedRADocuments.length > 0 ? true : false
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setChatHistory(data.data.chat_history)
        setnewQuestion('')
        setAnswer(data.data)
        setProcessingAnswer(false)
      })
      .catch((error) => {
        console.log(error)
        setProcessingAnswer(false)
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please resubmit your question.'
        })
      })
  }

  const scrollRef = React.useRef(null)

  // Use useEffect to perform the scroll on mount
  React.useEffect(() => {
    // Check if the current ref is not null
    if (scrollRef.current && processingAnswer === false) {
      // Scroll to the bottom of the div
      const scrollHeight = scrollRef.current.scrollHeight
      scrollRef.current.scrollTop = scrollHeight
    }
  }, [processingAnswer])

  const handleDocumentSelect = (id) => {
    const index = selectedRADocuments.indexOf(id)
    if (index !== -1) {
      const newArray = selectedRADocuments.filter((item) => item !== id)
      setSelectedRADocuments(newArray)
    } else {
      // String doesn't exist, add it to the array
      setSelectedRADocuments([...selectedRADocuments, id])
    }
  }

  return (
    <div className="commons-view-container-content">
      <div className="commons-view-main-content" style={{ paddingLeft: 32, height: '100%', paddingBottom: 32 }}>
        <h1 className="commons-view-main-content-settings-title" style={{ marginTop: 0 }}>
          Insight Commons Research Assistant (Beta)
        </h1>
        <p>
          Interact with the resources in your commons by asking questions to our large language model, specifically
          tailored to assist you. Rather than sifting through multiple documents to find the information you need, you
          can simply pose a question and receive a targeted, text-based answer that relates directly to the materials in
          your commons.
        </p>
        <p>
          While the model is trained to provide accurate and relevant information, please note that its responses should
          not replace professional advice or rigorous academic research, especially in critical and specialized areas of
          international development and humanitarian aid.
        </p>
        <Alert
          style={{ marginTop: 16, marginBottom: 24 }}
          message="Responses can take anywhere between 5 second to 20 seconds to respond."
          type="warning"
        />
        <div
          ref={scrollRef}
          className="research-assistant-history"
          style={chatHistory.length > 0 ? { height: 350 } : { height: 200 }}
        >
          {chatHistory.length > 0 ? (
            chatHistory.map((chat) => {
              return (
                <div key={chat[0]}>
                  <div
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1',
                      padding: 8,
                      minHeight: 80,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Avatar
                        style={{
                          backgroundColor: 'rgb(217, 217, 227)',
                          color: 'rgba(0, 0, 0, 0.85)',
                          verticalAlign: 'middle',
                          marginRight: 32
                        }}
                        size="large"
                      >
                        {info?.user?.name.substring(0, 1) || 'U'}
                      </Avatar>
                    </div>
                    <p style={{ margin: 0, fontSize: 16 }}>{chat[0]}</p>
                  </div>
                  {chat[1] ? (
                    <div
                      style={{
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1',
                        padding: 8,
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 80,
                        backgroundColor: 'rgb(217, 217, 227)'
                      }}
                    >
                      <div>
                        <Avatar
                          style={{
                            backgroundColor: '#13c2c2',
                            verticalAlign: 'middle',
                            marginRight: 32
                          }}
                          size="large"
                        >
                          RA
                        </Avatar>
                      </div>
                      <p style={{ margin: 0, fontSize: 16 }}>{chat[1]}</p>
                    </div>
                  ) : null}
                </div>
              )
            })
          ) : (
            <div
              style={{
                width: '100%',
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  width: '80%',
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 48 }} />{' '}
                  <p style={{ fontSize: 22, fontWeight: 'bold', marginTop: 16 }}>How can I help you today?</p>
                </div>
              </div>
            </div>
          )}
          {answer.result.length > 0 ? (
            <>
              <div
                style={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1',
                  padding: 8,
                  display: 'flex',
                  paddingTop: 24,
                  minHeight: 80,
                  backgroundColor: 'rgb(217, 217, 227)'
                }}
              >
                <div>
                  <Avatar
                    style={{
                      backgroundColor: '#13c2c2',
                      verticalAlign: 'middle',
                      marginRight: 32
                    }}
                    size="large"
                  >
                    RA
                  </Avatar>
                </div>
                <div>
                  <p style={{ margin: 0, fontWeight: 'bold', marginBottom: 16 }}>{answer['result']}</p>
                  {answer['sources'].map((source) => {
                    var pattern = /\/(\d+)\.txt$/
                    let documentName = source[1][1]['source'].match(pattern)
                    let paperName
                    if (documentName) {
                      documentName = documentName[1]
                      paperName = commonsInfo.literature.filter((paper) => paper.literature_id_id == documentName)
                    } else {
                      let privatePattern = /\/(\d+)-.*\.txt$/
                      documentName = source[1][1]['source'].match(privatePattern)
                      documentName = documentName[0]
                      documentName = documentName.substring(1, documentName.length)
                      documentName = documentName.replace('.txt', '')
                      paperName = commonsInfo.literature.filter((paper) => paper.paper_name == documentName)
                      paperName[0].private = true
                    }
                    if (paperName.length === 0) {
                      return <p></p>
                    } else {
                      return (
                        <div key={paperName[0].paper_name}>
                          <a style={{ fontWeight: 'bold', color: '#0e9494' }} href={paperName[0]?.document_url}>
                            {paperName[0].private === true
                              ? paperName[0]?.paper_name.substring(3, paperName[0]?.paper_name.length - 4)
                              : paperName[0]?.paper_name}
                          </a>

                          <p style={{ marginTop: 16 }}>Page Content: {source[0][1]}</p>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </>
          ) : // Check if loading
          processingAnswer ? (
            <div
              style={{
                width: '100%',
                height: 200,
                backgroundColor: 'rgba(19, 194, 194, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <Spin className="spinner" />
                <p style={{ fontSize: 16, fontWeight: 'bold', marginTop: 16 }}>Looking for the answer...</p>
              </div>
            </div>
          ) : null}
        </div>
        <>
          <TextArea
            value={newQuestion}
            onChange={(e) => setnewQuestion(e.target.value)}
            rows={1}
            style={{ height: 48, paddingTop: 12 }}
            placeholder="What are the ongoing efforts of the World Food Programme (WFP) in Somalia?"
          />
          <Button
            disabled={processingAnswer ? true : false}
            loading={processingAnswer ? true : false}
            onClick={() => askQuestion()}
            type="primary"
            style={{ float: 'right', marginTop: 16 }}
          >
            Ask Question
          </Button>
        </>
      </div>
      <div className="commons-view-second-content">
        <p className="commons-view-second-content-title">Choose the resources that you wish to interact with</p>
        {commonsInfo.literature.map((lit) => {
          const key = lit.literature_id_id ? lit.literature_id_id.toString() : lit.paper_name
          return (
            <div key={lit.id}>
              <Checkbox
                checked={selectedRADocuments.length === 0 || selectedRADocuments.includes(key) ? true : false}
                onChange={() => handleDocumentSelect(key)}
              >
                <a style={{ marginTop: 16 }} href={lit.document_url}>
                  {lit.literature_id_id ? lit.paper_name : lit.paper_name.substring(3, lit.paper_name.length - 4)}
                </a>{' '}
                | {lit.organization}
              </Checkbox>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ResearchAssitant
