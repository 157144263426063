import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Button, Input } from 'antd'
import { HeatMapOutlined } from '@ant-design/icons'
import Navbar from '../../components/Navbar/Navbar'
import './auth.css'
const { Content } = Layout

function ResetPassword() {
  const [email, setEmail] = React.useState('')
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const navigate = useNavigate()

  const sendEmail = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}auth/password/reset/ `, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setShowConfirmation(true)
      })
      .catch((error) => {
        setShowConfirmation(true)
      })
  }
  return (
    <React.Fragment>
      <Navbar />
      <Content className="auth-background">
        <div className="auth-container">
          <div className="auth-login-container">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 36, marginBottom: 24 }} />
            </div>
            {showConfirmation ? (
              <div>
                <p style={{ marginTop: 24 }}>We have emailed you a link to reset your password</p>
                <Button
                  onClick={() => navigate('/login/')}
                  style={{ width: '100%', marginTop: 32, marginBottom: 32 }}
                  type="primary"
                  htmlType="submit"
                >
                  Login
                </Button>
              </div>
            ) : (
              <>
                <p style={{ marginBottom: 32 }}>
                  Enter the email address associated with your account and we will send you a link to reset your
                  password.
                </p>
                <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />

                <Button
                  onClick={() => sendEmail()}
                  style={{ width: '100%', marginTop: 32, marginBottom: 32 }}
                  type="primary"
                  htmlType="submit"
                >
                  Send Link
                </Button>
              </>
            )}
          </div>
          <div className="auth-login-next-container">
            <p>
              New to Insight Commons?{' '}
              <span style={{ color: '#13C2C2', cursor: 'pointer' }} onClick={() => navigate('/register/')}>
                Create an Account
              </span>
            </p>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default ResetPassword
