import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout, Button } from 'antd'
import { HeatMapOutlined } from '@ant-design/icons'
import Navbar from '../../components/Navbar/Navbar'
import './auth.css'
const { Content } = Layout

function AccountVerification(params) {
  const { key } = useParams()
  const [message, setMessage] = React.useState()

  const navigate = useNavigate()
  const verifyAccount = (values) => {
    fetch(`${process.env.REACT_APP_BASE_URL}auth/registration/verify-email/`, {
      method: 'POST',
      body: JSON.stringify({ key }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage('Your account has been verified. You will be redirected to the login page.')
        setTimeout(function (x) {
          localStorage.clear()
          return (window.location.href = '/login/')
        }, 5000)
      })
      .catch((error) => {
        setMessage(
          'An error has occured approving your account. Please contact support if the problem persists. support@insightcommons.com'
        )
      })
  }
  React.useEffect(() => {
    verifyAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <React.Fragment>
      <Navbar />
      <Content className="auth-background">
        <div className="auth-container">
          <div className="auth-login-container" style={{ width: '100%', textAlign: 'center' }}>
            <div>
              <HeatMapOutlined style={{ color: '#13c2c2', fontSize: 36, marginBottom: 24 }} />
            </div>

            <p style={{ width: '50%', marginLeft: '25%' }}>{message}</p>
            <Button
              onClick={() => navigate('/login/')}
              style={{ marginBottom: 32, width: '50%' }}
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default AccountVerification
