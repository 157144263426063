import * as React from 'react'
import Chart from './Chart'

function GBVPipeline(params) {
  const { data, createRadarData } = params
  const gbv_presence_type = data[0].data.filter((insight) => insight.insight_type === 'gbv_presence_type')
  const gbv_vulnerable_groups = data[0].data.filter((insight) => insight.insight_type === 'gbv_vulnerable_groups')
  const gbv_services_for_survivors = data[0].data.filter(
    (insight) => insight.insight_type === 'gbv_services_for_survivors'
  )
  const gbv_prevention_intervention = data[0].data.filter(
    (insight) => insight.insight_type === 'gbv_prevention_intervention'
  )
  const gbv_barriers_to_reporting = data[0].data.filter(
    (insight) => insight.insight_type === 'gbv_barriers_to_reporting'
  )
  const gbv_perpetrator_factors = data[0].data.filter((insight) => insight.insight_type === 'gbv_perpetrator_factors')
  const gbv_support_systems = data[0].data.filter((insight) => insight.insight_type === 'gbv_support_systems')
  const gbv_societal_reactions = data[0].data.filter((insight) => insight.insight_type === 'gbv_societal_reactions')

  const loadHeatmap = () => {
    const agg_types = [
      'gbv_presence_type',
      'gbv_vulnerable_groups',
      'gbv_services_for_survivors',
      'gbv_barriers_to_reporting',
      'gbv_perpetrator_factors',
      'gbv_support_systems',
      'gbv_societal_reactions'
    ]

    agg_types.forEach((type) => {
      const typeData = data[0].data.filter((insight) => insight.insight_type === type)

      // Given an array of data create a list of unique papernames and labels
      const paperValues = typeData.map((obj) => obj['paper_name'])
      const titleValues = typeData.map((obj) => obj['title'])

      // Use a Set to get unique values
      const uniquePaperValues = [...new Set(paperValues)]
      const uniqueTitleValues = [...new Set(titleValues)]

      // set the dimensions and margins of the graph
      var margin = { top: 30, right: 30, bottom: 200, left: 300 },
        width = 1065 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom

      const d3 = window.d3

      // Check if svg already exists
      let existingSvg = d3.select(`#${type}`)
      existingSvg = existingSvg.select('svg')
      if (existingSvg.empty() !== true) {
        existingSvg.remove()
      }
      var svg = d3
        .select(`#${type}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // Build X scales and axis:
      var x = d3.scaleBand().range([0, width]).domain(uniqueTitleValues).padding(0.1)
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', function (d) {
          return 'rotate(-50)'
        })

      // Build y scales and axis:
      var y = d3.scaleBand().range([height, 0]).domain(uniquePaperValues).padding(0.1)
      svg.append('g').call(d3.axisLeft(y))

      // Build color scale
      var myColor = d3.scaleLinear().range(['white', '#13c2c2']).domain([1, 100])

      //transform the data

      typeData.forEach((data) => {
        data.group = data.paper_name
        data.variable = data.title
        data.value = 100
      })

      svg
        .selectAll()
        .data(typeData, function (d) {
          return d.group + ':' + d.variable
        })
        .enter()
        .append('rect')
        .attr('x', function (d) {
          return x(d.variable)
        })
        .attr('y', function (d) {
          return y(d.group)
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .style('fill', function (d) {
          return myColor(d.value)
        })
    })
  }

  React.useEffect(() => {
    // Create a script element
    const script = document.createElement('script')
    script.src = 'https://d3js.org/d3.v4.js'
    script.async = true // Optional: Makes the script load asynchronously
    script.onload = () => {
      loadHeatmap()
    }

    // Append the script to the document's body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}></div>
      <Chart
        title="Types of Gender Based Violence"
        data={gbv_presence_type}
        createRadarData={createRadarData}
        description="This component aims to categorize and define various forms of gender-based violence, such as physical, sexual, psychological, and economic violence, to understand the breadth and nature of GBV."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_presence_type"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Vulnerable Groups"
        data={gbv_vulnerable_groups}
        createRadarData={createRadarData}
        description="Identifying vulnerable groups involves recognizing populations at higher risk of experiencing GBV, including women, children, LGBTQ+ individuals, refugees, and marginalized communities."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_vulnerable_groups"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Services for Survivors"
        data={gbv_services_for_survivors}
        createRadarData={createRadarData}
        description="This aspect focuses on documenting the support and services available to survivors of GBV, including counseling, legal aid, healthcare, and safe shelters, to ensure that survivors receive appropriate assistance."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_services_for_survivors"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Preventive Interventions"
        data={gbv_prevention_intervention}
        createRadarData={createRadarData}
        description="This part of the pipeline explores strategies and programs aimed at preventing GBV, including awareness campaigns, educational initiatives, policy changes, and community-based interventions."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_prevention_intervention"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Barriers to Reporting"
        data={gbv_barriers_to_reporting}
        createRadarData={createRadarData}
        description="Identifying barriers to reporting GBV involves understanding the obstacles that survivors face when trying to report incidents, such as fear, stigma, legal constraints, and lack of trust in the justice system."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_barriers_to_reporting"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Perpetrator Factors"
        data={gbv_perpetrator_factors}
        createRadarData={createRadarData}
        description="This task aims to identify and analyze factors associated with Gender-Based Violence (GBV) perpetrators, including impunity, repeat offending, etc."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_perpetrator_factors"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Available Support Systems"
        data={gbv_support_systems}
        createRadarData={createRadarData}
        description="This component assesses the infrastructure and networks in place to support GBV survivors, including NGOs, government agencies, hotlines, and community organizations, ensuring that survivors can access help when needed."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_support_systems"></div>
      </div>
      {/* -------------------------------------- */}
      <Chart
        title="Societal Reactions"
        data={gbv_societal_reactions}
        createRadarData={createRadarData}
        description="Exploring societal reactions involves examining how communities respond to GBV, including cultural norms, attitudes, and the effectiveness of social movements and advocacy efforts in combating GBV."
      />
      <div style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
        <div id="gbv_societal_reactions"></div>
      </div>
      {/* -------------------------------------- */}
    </>
  )
}

export default GBVPipeline
