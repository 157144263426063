import * as React from 'react'
import { Layout, message, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../../components/Navbar/Navbar'
import './Explore.css'
// Import Components
import TopCommons from '../Components/TopCommons'
import SearchSidebar from '../Components/SearchSidebar'
import ExploreContent from './ExploreContent'
import SearchContent from '../Components/SearchContent'
import SearchCommons from '../Components/SearchCommons'
import TopicsContent from '../Components/TopicsContent'
// Import And D
const { Content } = Layout

function Explore(props) {
  const navigate = useNavigate()
  const { startingTab } = props
  const [messageApi, contextHolder] = message.useMessage()
  const [datasets, setDatasets] = React.useState(false)
  const [searchResults, setSearchResults] = React.useState([])
  const [searchCommons, setSearchCommons] = React.useState([])
  const [activeTab, setActiveTab] = React.useState(startingTab || 'explore')
  const [runningSearch, setRunningSearch] = React.useState(false)

  React.useEffect(() => {
    setActiveTab(startingTab)
  }, [startingTab])

  // React.useEffect(() => {
  //   setSearchResults([])
  // }, [datasets])

  const handleTabChange = (tab) => {
    navigate(`/${tab}/`)
    // setActiveTab(tab)
  }

  const setSearchDatasets = (value) => {
    setSearchResults([])
    setDatasets(value)
  }

  return (
    <React.Fragment>
      <Navbar />
      <Content className="explore-container">
        {contextHolder}
        <div className="top-commons-sidebar">
          {activeTab === 'explore' ? <TopCommons messageApi={messageApi} /> : null}
          {activeTab === 'search' ? (
            <SearchSidebar
              datasets={datasets}
              setDatasets={setSearchDatasets}
              messageApi={messageApi}
              setRunningSearch={setRunningSearch}
              setSearchResults={setSearchResults}
            />
          ) : null}
          {activeTab === 'topics' ? (
            <SearchCommons messageApi={messageApi} setSearchCommons={setSearchCommons} />
          ) : null}
        </div>
        <div className="main-content-container">
          <Tabs activeKey={activeTab} onChange={(activeTab) => handleTabChange(activeTab)} defaultActiveKey="explore">
            <Tabs.TabPane tab="Explore" key="explore">
              <ExploreContent messageApi={messageApi} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Search" key="search">
              <SearchContent
                datasets={datasets}
                runningSearch={runningSearch}
                messageApi={messageApi}
                searchResults={searchResults}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default Explore
