import * as React from 'react'
import { Button, Spin } from 'antd'
import { Link } from 'react-router-dom'

function ExploreContent(props) {
  const { messageApi } = props
  const [exploreCommons, setExploreCommons] = React.useState([])
  const [trendingUsers, setTrendingUsers] = React.useState([])

  const getCommonsExplore = (user, token) => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-explore/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setExploreCommons(data)
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error loading the commons you might be interested in. Please try reloading the page.'
        })
      })
  }
  const getTrendingUsers = (user, token) => {
    fetch(`${process.env.REACT_APP_BASE_URL}commons-trending-users/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setTrendingUsers(data)
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error loading the trending users. Please try reloading the page.'
        })
      })
  }
  React.useEffect(() => {
    // Call mounting api calls
    const info = JSON.parse(localStorage.getItem('userInfo'))
    const userId = info.user?.id || info.user?.pk
    const token = info.access_token
    getCommonsExplore(userId, token)
    getTrendingUsers(userId, token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h2 className="main-content-container-explore-heading">Some Commons that might interest you...</h2>
      <div className="explore-content-container">
        <div className="explore-content-commons-list">
          {exploreCommons.length === 0 ? (
            <Spin />
          ) : (
            exploreCommons.map((commons) => {
              return (
                <div key={commons.id} className="commons-list-container">
                  <div className="commons-list-title-container">
                    <p>{commons.title}</p>
                    <Button type="link">
                      <Link to={`/commons-home/${commons.id}`}>View Commons</Link>
                    </Button>
                  </div>
                  <div className="commons-list-content-container">{commons.description}</div>
                </div>
              )
            })
          )}
        </div>
        <div className="explore-trending-users-list">
          <div className="commons-list-title-container">
            <p>Trending Contributors</p>
          </div>
          {trendingUsers.length === 0 ? (
            <Spin />
          ) : (
            trendingUsers.map((user) => {
              return (
                <div key={user.id} className="explore-trending-user">
                  <p className="explore-trending-user-name">{`${user.name}`}</p>
                  <p>
                    {user.organization.length > 35 ? user.organization.substring(0, 35) + '...' : user.organization}
                  </p>
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

export default ExploreContent
