import * as React from 'react'
import { Button, TreeSelect } from 'antd'
import countries from '../../../utils/countries'
import themes from '../../../utils/themes'
const { TreeNode } = TreeSelect

function SearchCommons(props) {
  const { messageApi, setSearchCommons } = props
  const [searchTheme, setSearchTheme] = React.useState([])
  const [searchCountry, setSearchCountry] = React.useState([])
  const searchCommons = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}search-commons/`, {
      method: 'POST',
      body: JSON.stringify({
        themes: searchTheme,
        countries: searchCountry
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchCommons(data)
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'There was an error. Please try again.'
        })
      })
  }
  return (
    <>
      <div className="top-commons-heading">
        <h2>Search Commons</h2>
      </div>
      <p className="mobile-seach-filters-tree-titles">Theme</p>
      <TreeSelect
        showSearch
        value={searchTheme || []}
        treeCheckable
        style={{
          width: '100%',
          height: 32,
          overflowX: 'auto'
        }}
        onChange={(e) => setSearchTheme(e)}
        placeholder="Select a Theme of Interest"
        allowClear
      >
        {themes.map((theme) => {
          return <TreeNode key={theme} value={theme} title={theme}></TreeNode>
        })}
      </TreeSelect>
      <p className="mobile-seach-filters-tree-titles">Country</p>
      <TreeSelect
        showSearch
        style={{
          width: '100%'
        }}
        treeCheckable
        onChange={(e) => setSearchCountry(e)}
        placeholder="Select a Country of Interest"
        allowClear
        value={searchCountry || []}
      >
        {Object.entries(countries)
          .sort((a, b) => (a > b ? 1 : -1))
          .map((country) => {
            return <TreeNode key={country[1]} value={country[1]} title={country[1]}></TreeNode>
          })}
      </TreeSelect>
      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <Button onClick={() => searchCommons()} size="small" type="primary">
          Search Commons
        </Button>
      </div>
    </>
  )
}

export default SearchCommons
