const contentTypes = [
  'Analysis',
  'Appeal',
  'Assesment',
  'Evaluation & Lessons Learned',
  'Manual and Guidelines',
  'Situation Report'
]

export default contentTypes
